import React, { Component } from "react";

import ReactDataSheet from "react-datasheet";

import { Button } from "react-bootstrap";

import "./ImportPreviewGrid.css";
import GooglePlacesAutocomplete from "react-google-places-autocomplete/dist/GooglePlacesAutocomplete";
import { BASE_URL } from "configs/Constants";
import { getToken } from "utils/utilities";
import { put } from "actions/fetch_requests";
import LocationAccurancy from "./LocationAccurancy";

const LOCATION_COL = 4;
export default class ImportPreviewGrid extends Component {
  previewData = [];
  ovalLoaderRef = null;

  constructor(props) {
    super(props);
    this.state = {
      grid: [],
      isModalVisible: false,
      isLoading: false,
    };
  }

  showPreview = (data) => {
    const statusWidth = 60;
    // const locationAccurincyWidth = 70
    this.previewData = data;
    const singleCol = (colName) => {
      return { value: colName, readOnly: true, className: 'min_table_width' };
    };
    const singleData = (data) => {
      let className = "";
      if (data == null || data == "") {
        className = "cell-error";
      }
      return { value: data, className };
    };
    // const booleanStaus = ({value}) => {
    //   return (
    //       <Button 
    //         style={{
    //           background: (value) ? "green" : "red",
    //           textAlign: 'center'
    //         }}
    //       />
    //   )
    // } 
    const booleanStaus = ({value}) => {
      return (
        <input type="checkbox" style={{
          cursor: 'cell'
        }} checked={value} />
        // <div>{value}</div>
      )
    }
    const booleanData = (data) => {
      let className = "";
      if (data == null || data == "") {
        //className = "cell-error";
      }
      return { className: 'min_table_width' , value: data,
      valueViewer: (booleanStaus), 
      className,
      dataEditor: (({value,onChange}) => {
      return <input type="checkbox" checked={value} value={1} onChange={(event)=> {
        const target = event.target;
        value=target.checked
        onChange(value)
      }} />}), 
    };
    };
    const singleDataWithInvalid = (data) => {
      let className = "";
      if (data == null || data == "") {
        className = "cell-error";
      }
      return {
        value: data,
        className,
        valueViewer: (props) => {
          if (props.value == null || props.value == "") {
            return (
              <Button
                style={{
                  background: "red",
                }}
                className="btn btn-danger"
              >
                INVALID
              </Button>
            );
          } else {
            return props.value;
          }
        },
      };
    };
    const singleDataDate = (date) => {
      return {
        value: date,
        // dataEditor: ((props) => {

        //     // console.log(props.value)
        //     // alert(props.value)
        //     if(props.value){
        //       this.state.date = moment(props.value)
        //     }
        //     return (
        //       <SingleDatePicker
        //       date={this.state.date}
        //       onDateChange={date => {
        //         this.setState({ date })
        //         props.onChange(date.toLocaleString());
        //       }}
        //       focused={true}
        //       onFocusChange={({ focused }) => {

        //       }}
        //        />
        //     )
        // })
      };
    };

    const locationData = (location, row, col) => {
      return {
        ...singleData(location),
        dataEditor: (props) => (
          <GooglePlacesAutocomplete
            initialValue={props.value}
            onSelect={(locationInfo) => {
              const value = locationInfo.description;
              props.onChange(value);
            }}
          />
        ),
      };
    };
    const showStatus = (status) => {
      if (status) {
        return {
          value: status,
          readOnly: true,
          width: statusWidth,
          valueViewer: (props) => {
            if (props.value == "SUCCESS")
              return (
                <Button
                  value="SUCCESS"
                  style={{
                    background: "green",
                  }}
                  className="btn btn-success"
                ></Button>
              );

            return (
              <Button
                style={{
                  background: "red",
                }}
                value="ERROR"
                className="btn btn-danger"
              />
            );
          },
        };
      }
    };
    const showLocationAccurancy = (location) => {
      return {
        value: location,
        disableEvents: true,
        valueViewer: (props) => <LocationAccurancy {...props} />,
      };
    };
    const singleDataRow = (data, row) => {
      return [
        showStatus(data.status),
        singleDataWithInvalid(data.event_title),
        singleData(data.description),
        singleDataWithInvalid(data.start_date),
        singleDataWithInvalid(data.end_date),
        booleanData(data.is_exportable),
        locationData(data.location, row + 1, LOCATION_COL),
        showLocationAccurancy(data.location),
        locationData(data.location1, row + 1, LOCATION_COL),
        showLocationAccurancy(data.location1),
        locationData(data.location2, row + 1, LOCATION_COL),
        showLocationAccurancy(data.location2),
        locationData(data.location3, row + 1, LOCATION_COL),
        showLocationAccurancy(data.location3),
        locationData(data.location4, row + 1, LOCATION_COL),
        showLocationAccurancy(data.location4),
      ];
    };
    let column = [
      { ...singleCol("Status"), width: statusWidth, className: null },
      singleCol("Title"),
      singleCol("Description"),
      singleCol("Start Date"),
      singleCol("End Date"),
      {...singleCol("Enabled"),width: statusWidth, className: null  },
      singleCol("Location 1"),
      singleCol("Location1 Accuracy"),
      singleCol("Location 2"),
      singleCol("Location2 Accuracy"),
      singleCol("Location 3"),
      singleCol("Location3 Accuracy"),
      singleCol("Location 4"),
      singleCol("Location4 Accuracy"),
      singleCol("Location 5"),
      singleCol("Location5 Accuracy"),
    ];

    let gridData = [column];
    data.map((data, row) => {
      gridData.push(this.checkCurrentCell(singleDataRow(data, row)));
    });
    this.setState({
      grid: gridData,
      isModalVisible: true,
    });
  };

  updateGrid = () => {
    return new Promise((resolve, reject) => {
      let toUpdateData = [];
      this.state.grid.map((data, i) => {
        if (i == 0) return;
        let singleRecord = {
          id: this.previewData[i - 1]["event_id"],
          calendar_id: this.previewData[i - 1]["calendar_id"],
          title: data[1]["value"],
          description: data[2]["value"],
          start_date: data[3]["value"],
          end_date: data[4]["value"],
          is_exportable: data[5]["value"],
          location: data[6]["value"],
          location1: data[8]["value"],
          location2: data[10]["value"],
          location3: data[12]["value"],
          location4: data[14]["value"],
        };
        toUpdateData.push(singleRecord);
      });
      const myHeaders = new Headers();
      let myToken = getToken();
      console.log("token", myToken);
      myHeaders.append("Authorization", `Bearer ${myToken}`);
      myHeaders.append("Content-Type", "application/json");
      put(`${BASE_URL}import/update`, myHeaders, toUpdateData)
        .then((data) => {
          this.showPreview(data.data);
          //this will update the up order component data holding.
          resolve(data.data);
        })
        .catch(() => {
          reject();
        });
    });
  };

  checkCurrentCell = ( gridRow ) => {

    try{
      let isEnabled = gridRow[5].value;
      let readOnly = false;
      if(!isEnabled)
      {
        readOnly = true;
      } 
        for(let forI=0;forI<gridRow.length;forI++)
        {
          if(forI==5) continue;
          gridRow[forI]['readOnly'] = readOnly;
        } 
      return gridRow;
    }catch(e){
      return gridRow;
    }
  }

  render() {
    return (
      <div
        style={{
          overflowY: "scroll",
          height: "70vh",
        }}
      >
        <ReactDataSheet
          data={this.state.grid}
          valueRenderer={(cell) => cell.value}
          onCellsChanged={(changes) => {
            const grid = this.state.grid.map((row) => [...row]);
            changes.forEach(({ cell, row, col, value }) => {
              grid[row][col] = { ...grid[row][col], value };
              grid[row]=this.checkCurrentCell(grid[row])
            });
            this.setState({ grid });
          }}
        />
      </div>
    );
  }
}
