import React, { Component } from "react";
import "./button.css";
import PropTypes from "prop-types";
import Colors from "./../../configs/Colors";
// TODO: Improper file name
// TODO: Why most of the code does not use this button
class Button extends Component {
  // Why not generalise all types of button into this component?
  render() {
    return (
      <button
        id={this.props.id}
        disabled={this.props.disabled}
        className={this.props.className}
        type={this.props.type}
        style={{ fontSize: 15, fontWeight: "bold", width: this.props.width }}
        // onClick={()=>this.props.onClick()}
        {...this.props}
      >
        {this.props.loader ? (
          <i className="fa fa-refresh fa-spinner fa-spin "></i>
        ) : this.props.icon != null ? (
          <i
            className={this.props.icon}
            style={{
              fontSize: this.props.fontSize,
              color: this.props.color,
              marginRight: (this.props.text!="" ? 8 : 0)
            }}
          />
        ) : (
          ""
        )}
        {this.props.text}
      </button>
    );
  }
}

Button.propTypes = {
  onClick: PropTypes.func,
  text: PropTypes.string,
  icon: PropTypes.string,
  width: PropTypes.string,
  backgroundColor: PropTypes.string,
  className: PropTypes.string,
  fontSize: PropTypes.number,
  type: PropTypes.string
};

Button.defaultProps = {
  text: '',
  width: "120px",
  className: "btn",
  icon: null,
  fontSize: 18,
  color: Colors.WHITE,
  type: "button",
  onClick: null
};

export default Button;
