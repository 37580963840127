import { BASE_URL } from "./../configs/Constants";
import {
  normalizeResponseErrors,
  fetchErrorHandler,
  getToken
} from "../utils/utilities";
import { get, Delete } from "./fetch_requests";

export const exportMileageHistory = (
  start,
  end,
  location,
  calendar,
  unit,
  output = "xlsx"
) => {
  let token = getToken();
  let calendars = "";
  calendar.forEach(cal => {
    calendars = calendars.concat(`calendars[]=${cal}&`);
  });
  const header = new Headers();
  header.append(`Authorization`, `Bearer ${token}`);
  return get(
    `${BASE_URL}export?start_date=${start}&end_date=${end}&from_location=${location}&output=${output}&unit=${unit}&${calendars}`,
    header
  );
};
export const exportHistory = () => {
  let token = getToken();

  const header = new Headers();
  header.append(`Authorization`, `Bearer ${token}`);

  return get(`${BASE_URL}export/history`, header);
};

export const deleteHistory = id => {
  let token = getToken();

  const header = new Headers();
  header.append(`Authorization`, `Bearer ${token}`);

  return Delete(`${BASE_URL}export/${id}`, header);
};

export const showWarningMsg = () => {
  let token = getToken();

  const header = new Headers();
  header.append(`Authorization`, `Bearer ${token}`);
  return get(`${BASE_URL}credit-points`, header);
};
