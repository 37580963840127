import React, { Component } from 'react'
import Loader from 'react-loader-spinner'
import PropTypes from 'prop-types'

class OvalLoader extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isLoading: false
        };
    };

    showLoader = () => {
        this.setState({
            isLoading: true
        })
    }

    hideLoader = () => {
        this.setState({
            isLoading: false
        })
    }

    render() {
        return (
            <Loader 
                style={{ backgroundColor: '#ffffff' }} 
                type="Oval" 
                color="#000000" 
                height={60} 
                width={60}     
            />
        )
    }
}

export default OvalLoader
