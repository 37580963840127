import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "./login.css";
import { Link } from "react-router-dom";
import logo from "assets/img/logo-transparent-200-200.png";
class CustomHeader extends Component {
  render() {
    return (
      <div>
        <Link className="navbar-brand" to={"/login"} style={{ color: "black" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginLeft: "50px"
            }}
          >
            <div className="logo-img">
              <img
                id='logo-image'
                style={{
                  width: 110,
                  position: "absolute",
                  left: "150px",
                  top: "-10px"
                }}
                src={logo}
              />
              {/* alt="logo_image" */}
            </div>
          </div>
        </Link>
      </div>
    );
  }
}

export default CustomHeader;
