// export const BASE_URL = "http://dev.gogosoon.com/mileage2go-services/public/api/";
// export const BASE_URL = "http://localhost/Mileage2Go/public/api/";
// TODO: Define constants in each local packages 

export const BASE_URL = process.env.REACT_APP_API_URL;
export const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID

export const DEV = process.env.NODE_ENV !== "production";

export const STRIPE_KEY = process.env.REACT_APP_STRIPE_KEY;


// toast configuration
export const TOAST_AUTOCLOSE = 3000;
export const TOAST_POSITION = "top-center";

export const LOCALSTORAGE_KEY_AUTH_TOKEN = "AuthToken";
export const LOCALSTORAGE_KEY_USER_INFO = "UserInfo";

export const ICON_COLOR = "#1eb7ff";

export const MAP_URL_REDIRECT = "https://maps.google.com/maps?q=" ;
