import React, { Component } from "react";
import "./button.css";
import PropTypes from "prop-types";

class CustomColorButton extends Component {
  render() {
    return (
      <button
        className="btn"
        type="button"
        style={{ backgroundColor: this.props.backgroundColor, width: "35px", height: "35px", borderColor: this.props.borderColor }}
        onClick={() => this.props.onClick()}
      >
        {this.props.text}
      </button>
    );
  }
}

CustomColorButton.propTypes = {
  onClick: PropTypes.func,
  text: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  backgroundColor: PropTypes.string,
  borderColor: PropTypes.string
};

CustomColorButton.defaultProps = {
  text: " ",
  backgroundColor: ""
};

export default CustomColorButton;
