import { MyEvents, Imports, Subscriptions, Invoice } from "./views";
import { MilageExport, HistoryExport } from "./views/exports";
import Home from "views/Home";

const dashboardRoutes = [{
        path: "/home",
        name: "Home",
        icon: "pe-7s-home",
        component: Home,
        layout: "/admin"
    },
    {
        path: "/Import",
        name: "Import",
        icon: "pe-7s-upload",
        component: Imports,
        layout: "/admin"
    },
    {
        path: "/calender",
        name: "My Events",
        icon: "pe-7s-date",
        component: MyEvents,
        layout: "/admin"
    },
    {
        collapse: true,
        path: "/Export",
        name: "Export",
        icon: "pe-7s-download",
        layout: "/admin",
        views: [{
                path: "/mileage",
                layout: "/admin",
                icon: "pe-7s-car",
                name: "Mileage",
                mini: "M",
                component: MilageExport
            },
            {
                path: "/history",
                layout: "/admin",
                icon: "pe-7s-albums",
                name: "History",
                mini: "H",
                component: HistoryExport
            }
        ]
    },

    {
        path: "/Subscriptions",
        name: "Subscription",
        icon: "pe-7s-credit",
        component: Subscriptions,
        layout: "/admin"
    },
    {
        path: "/Invoice",
        name: " My Invoice",
        icon: "pe-7s-wallet",
        component: Invoice,
        layout: "/admin"
    }
];

export default dashboardRoutes;