import React from "react";
import { Modal } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import BaseClass from "./../BaseClass";
import { DEV } from "../../configs/Constants";
import Button from "./../Button/CustomButton";
import { updatePassword } from "./../../actions/auth";
import { showErrorMsg } from "utils/utilities";

class UpdatePassword extends BaseClass {
  constructor(props) {
    super(props);
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.UpdatePassword = this.UpdatePassword.bind(this);

    this.state = {
      show: false,
      opassword: "",
      npassword: "",
      rnpassword: "",
      isLoading: false
    };
  }
  devFill = () => {
    if (DEV) {
      this.setState({
        opassword: "",
        npassword: "",
        rnpassword: ""
      });
    }
  };

  componentDidMount = () => {
    this.devFill();
  };
  handleClose = () => {
    this.resetFields()
    this.setState({ show: false });
  };

  handleShow = () => {
    this.setState({ show: true });
  };

  resetFields = () => {
    this.setState({
      opassword: "",
      npassword: "",
      rnpassword: ""
    })
  }

  UpdatePassword = () => {
    if (this.validator.allValid()) {
      this.LoaderStart();
      if (this.state.npassword === this.state.rnpassword) {
        updatePassword(this.state.opassword, this.state.npassword)
          .then(json => {
            if (json && json.status === "SUCCESS") {
              this.handleClose();
            }
          })
          .finally(() => {
            this.LoaderStop();
          });
      } else {
        showErrorMsg("New Password and Confirm Password are not same")
      }
    }
    else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };
  render() {
    return (
      <div>
        <Modal
          style={{ zIndex: 1500 }}
          show={this.state.show}
          onHide={this.handleClose}
          style={{
            zIndex: 1500
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title> Update Password</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group">
              <label>Old password</label>

              {/* TODO: This part could be separated as a component rather than the whole */}
              <input
                type="password"
                className="form-control"
                value={this.state.opassword}
                placeholder="Enter old password"
                onChange={this.handleInputChange}
                name="opassword"
              />
              {this.validator.message(
                "password",
                this.state.opassword,
                "required|string"
              )}
            </div>
            <div className="form-group">
              <label>New password</label>
              <input
                type="password"
                className="form-control"
                value={this.state.npassword}
                placeholder="Enter new password"
                onChange={this.handleInputChange}
                name="npassword"
              />
              {this.validator.message(
                "password",
                this.state.npassword,
                "required|string"
              )}
            </div>
            <div className="form-group">
              <label>Re-enter new password</label>
              <input
                type="password"
                className="form-control"
                placeholder="Re-enter new password"
                value={this.state.rnpassword}
                onChange={this.handleInputChange}
                name="rnpassword"
              />
              {this.validator.message(
                "password",
                this.state.rnpassword,
                "required|string"
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end"
            }}>
            <Button
              loader={this.state.isLoading}
              disabled={this.state.isLoading}              
              text={"Change"}
              onClick={this.UpdatePassword}
            />
            <Button
              onClick={this.handleClose}
              text={"Cancel"}
            />
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default UpdatePassword;
