import React, { Component } from "react";
import Colors from "../configs/Colors";
import PropTypes from "prop-types";

// TODO: File in wrong directory 

/**
 * @augments {Component<{  text:string>}
 */
class TickMarkWithText extends React.PureComponent {
  render() {
    return (
      <div style={styles.container}>
        <i className="fa fa-check" style={styles.icon} />
        <span style={styles.text}>{this.props.text}</span>
      </div>
    );
  }
}

const styles = {
  container: {
    textAlign: "left",
    marginLeft: 20
  },
  icon: {
    color: Colors.PRIMARY_BLUE,
    fontSize: 18,
    marginRight: 5
  },
  text: {
    fontSize: 13,
    fontFamily: "Serif",
    fontWeight: "bold"
  }
};

TickMarkWithText.defaultProps = {
  text: "undefined"
};

TickMarkWithText.propTypes = {
  text: PropTypes.string
};

export default TickMarkWithText;
