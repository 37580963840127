import React, { Component } from "react";
import { NavItem, Nav, NavDropdown, MenuItem } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import UpdatePassword from "./../UpdatePassword/UpdatePassword";
import UserProfile from "./../UserProfile/UserProfile";
import { removeToken, removeUserInfo, getUserInfo } from "./../../utils/utilities" ;
import { InitialAvatar } from 'components' ;

class AdminNavbarLinks extends Component {
  updatePasswordRef = null;
  constructor(props) {
    super();
    this.state = {
      name: ''
    }
    this.logout = this.logout.bind(this);
  }

  logout = () => {
    removeUserInfo();
    removeToken();
    let path = `/login`;
    this.props.history.push(path);
  };

  componentDidMount = () => {
    let { name } = getUserInfo() ;
    this.setState({
      name
    })
  };
  

  render() {
    const notification = (
      <div>
        <i className="fa fa-globe" />
        <b className="caret" />
        <span className="notification">5</span>
        <p className="hidden-lg hidden-md">Notification</p>
      </div>
    );

    const profile = (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          alignSelf: 'center',
        }}
      >
        <InitialAvatar 
          text={this.state.name.charAt(0).toUpperCase()}
        />
        {/* <img
          src="https://demos.creative-tim.com/light-bootstrap-dashboard-pro-react/static/media/default-avatar.f653841f.png"
          height="30"
          width="30"
          style={{
            borderRadius: 30,
            marginRight: 8
          }}
        /> */}
        <p
          style={{
            marginRight: 2,
            marginLeft: 8,
            marginTop: 10,
            backgroundColor: "transparent"
          }}
        >
          {this.state.name}
        </p>
        <b style={{ backgroundColor: "transparent", marginTop: 3 }} className="caret" />
      </div>
    );
    return (
      <div>
        <UpdatePassword
          ref={ref => {
            this.updatePasswordRef = ref;
          }}
        />
        <UserProfile
         {...this.props}
          ref={ref => {
            this.userProfileRef = ref;
          }}
        />
        <Nav>
          <NavItem eventKey={1} href="#">
            <p className="hidden-lg hidden-md">Dashboard</p>
          </NavItem>
        </Nav>
        <Nav pullRight>
          <NavDropdown
            eventKey={2}
            title={profile}
            noCaret
            id="basic-nav-dropdown"
          >
            <MenuItem 
              eventKey={2.1}
              onClick={() => this.userProfileRef.handleShow()}
            >
              
              My Profile</MenuItem>
            <MenuItem
              eventKey={2.2}
              onClick={() => this.updatePasswordRef.handleShow()}
            >
              Update Password
            </MenuItem>
            <MenuItem divider />
            <MenuItem onClick={this.logout} eventKey={2.5}>
              Logout
            </MenuItem>
          </NavDropdown>
        </Nav>
      </div>
    );
  }
}

export default withRouter(AdminNavbarLinks);
