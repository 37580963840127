import React, { Component } from 'react'
import { Modal, Button } from 'react-bootstrap'
import { CustomButton } from 'components' ;

class CustomConfirmationModal extends React.PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            isVisible: false
        }

        this.hideModal = this.hideModal.bind(this)
    }

    showModal = () => {
        this.setState({
            isVisible: true
        })
    }

    hideModal = () => {
        this.setState({
            isVisible: false
        })
    }

    render() {
        return (
            <Modal
                style={{ zIndex: 1500 }}
                show={this.state.isVisible}
                onHide={this.hideModal}
                bsSize="auto"
            >
                <Modal.Header  >
                    <Modal.Title>{this.props.title}</Modal.Title>
                </Modal.Header>

                <Modal.Body>{this.props.body}</Modal.Body>

                <Modal.Footer>
                    <CustomButton 
                        text={"Yes"}
                        onClick={() => this.props.confirmation(this.props.eventId)}     
                    />
                    <CustomButton
                        text={"No"} 
                        onClick={this.hideModal} 
                    />
                </Modal.Footer>
            </Modal>
        )
    }
}


export default CustomConfirmationModal;