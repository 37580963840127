import React, { Component } from "react";
import { Grid, Row, Col, Table } from "react-bootstrap";
import Card from "components/Card/Card.jsx";
import { thArr, tdArr } from "variables/Variables.jsx";
import Button from "./../../components/Button/CustomButton";
import { exportHistory } from "actions/export";
import { deleteHistory } from "actions/export";
import { CustomConfirmationModal } from "components";

class History extends Component {
  CustomConfirmationModalRef = null;

  constructor(props) {
    super(props);

    this.state = {
      history: [],
      deleteHistoryId: ""
    };
    this.deleteHistory = this.deleteHistory.bind(this);
  }

  componentDidMount = () => {
    this.loadExportHistory();
  };

  loadExportHistory = () => {
    exportHistory().then(({ data }) => {
      this.setState({
        history: data
      });
    });
  };

  deleteHistory = id => {
    deleteHistory(id).finally(() => {
      this.loadExportHistory();
      this.CustomConfirmationModalRef.hideModal();
    });
  };

  confirmDeleteHistory = historyId => {
    this.setState(
      {
        deleteHistoryId: historyId
      },
      () => {
        this.CustomConfirmationModalRef.showModal();
      }
    );
  };

  render() {
    const { history } = this.state;
    return (
      <div className="content">
        <CustomConfirmationModal
          eventId={this.state.deleteHistoryId}
          title={"Delete History"}
          body={"Are sure do you want to delete this exported history?"}
          confirmation={this.deleteHistory}
          ref={ref => {
            this.CustomConfirmationModalRef = ref;
          }}
        />
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Card
                title="Export History"
                ctTableFullWidth
                ctTableResponsive
                content={
                  <Table striped hover>
                    <thead>
                      {/* TODO: Improper variable names */}
                      <tr>
                        {thArr.map((prop, key) => {
                          return <th key={key}>{prop}</th>;
                        })}
                      </tr>
                    </thead>
                    {/* TODO: Why not export table as a component? */}
                    <tbody>
                      {history.map((prop, key) => {
                        return (
                          <tr key={key}>
                            <td>{prop.updated_at}</td>
                            <td>{prop.display_name}</td>
                            {/* <td>exported_file_{key}</td> */}
                            <td>{prop.exported_as}</td>
                            <td>
                              <div>
                                <Button
                                  icon={"fa fa-download"}
                                  style={{
                                    paddingHorizondal: 10,
                                    paddingTop: 10
                                  }}
                                  width={50}
                                  onClick={() =>
                                    window.open(prop.file_name, "_blank")
                                  }
                                />
                                <Button
                                  icon={"fa fa-trash"}
                                  style={{
                                    paddingHorizondal: 10,
                                    paddingTop: 10,
                                    marginLeft: 10,
                                    backgroundColor: "red"
                                  }}
                                  width={50}
                                  onClick={() => {
                                    this.confirmDeleteHistory(prop.export_id);
                                  }}
                                />
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                }
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default History;
