import { BASE_URL } from "./../configs/Constants";
import {
  normalizeResponseErrors,
  fetchErrorHandler,
  getToken
} from "../utils/utilities";

export const post = async (url, header, body) => {
  return new Promise((resolve, reject) => {
    fetch(url, {
      method: "POST",
      headers: header,
      body: JSON.stringify(body)
    })
      .then(res => normalizeResponseErrors(res))
      .then(responseJson => resolve(responseJson))
      .catch(err => {
        fetchErrorHandler(err);
        reject(err);
      });
  })
  .catch(err => {
    return err;
  });
};

export const put = async (url, header, body) => {
  return new Promise((resolve, reject) => {
    fetch(url, {
      method: "PUT",
      headers: header,
      body: JSON.stringify(body)
    })
      .then(res => normalizeResponseErrors(res))
      .then(responseJson => resolve(responseJson))
      .catch(err => {
        fetchErrorHandler(err);
        reject(err);
      });
  }).catch(err => {
  });
};

export const get = async (url, header) => {
  return new Promise((resolve, reject) => {
    fetch(url, {
      method: "GET",
      headers: header
    })
      .then(res => normalizeResponseErrors(res))
      .then(responseJson => resolve(responseJson))
      .catch(err => {
        fetchErrorHandler(err);
        reject(err);
      });
  }).catch(err => {
  });;
};

export const Delete = async (url, header) => {
  return new Promise((resolve, reject) => {
    fetch(url, {
      method: "DELETE",
      headers: header
    })
      .then(res => normalizeResponseErrors(res))
      .then(responseJson => resolve(responseJson))
      .catch(err => {
        fetchErrorHandler(err);
        reject(err);
      });
  }).catch(err => {
  });
};

export const postBodyNotStringify = async (url, header, body) => {
  return new Promise((resolve, reject) => {
    fetch(url, {
      method: "POST",
      headers: header,
      body: body
    })
      .then(res => normalizeResponseErrors(res))
      .then(responseJson => resolve(responseJson))
      .catch(err => {
        fetchErrorHandler(err);
        reject(err);
      });
  }).catch(err => {
  });
};


export const getWithAbort = (url) => {
    
  const controller = new AbortController;
  const signal = controller.signal; // get signal
  const params = {
    method: 'GET',
    signal, // bind controller to this fetch request using signal
  };
  const promise = fetch(url,params).then(res => res.json())
  return [
    promise,
    controller.abort.bind(controller), // use bind to save controller context
  ];
}