import React from "react";
import "./assets/css/style-homepage.css";

import "./assets/vendor/boxicons/css/boxicons.min.css";

import logo from "../Second/assets/img/mileage2goLogo.png";
import one from "../Second/assets/img/gallery/googleImportButton.png";
import two from "../Second/assets/img/gallery/selectMonthRange.png";
import three from "../Second/assets/img/gallery/locationSelector.png";
import four from "../Second/assets/img/gallery/analyzeImportEntries.png";
import five from "../Second/assets/img/gallery/exportExcel.png";
import about from "../Second/assets/img/about.png";

import Login from "views/auth/Login";
import SignupBody from "components/Auth/SignupBody";
import GoogleButton from "components/Auth/LoginWithGoogle";
import { saveToken, saveUserInfo, getToken } from "utils/utilities";
import PageSplitter from "components/PageSplitter/PageSplitter";
import Button from "components/Button/CustomButton";
import { Link } from "react-router-dom";
import { login } from "actions/auth";
import BaseClass from "components/BaseClass";
import SimpleReactValidator from "simple-react-validator";
import { BASE_API } from "configs/Constants";
import { BASE_URL } from "configs/Constants";
import { getAllSubscriptionPlansForHomePage } from "actions/subscriptions";

const justifyCenter = {
  display: "flex",
  justifyContent: "center",
  marginTop: 5
};

export default class HomePage extends React.Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator();
    this.handleInputChange = this.handleInputChange.bind(this);
    this.LoaderStop = this.LoaderStop.bind(this);
    this.LoaderStart = this.LoaderStart.bind(this);
    this.state = {
      subscriptions: [],
      faq_1: 1,
      faq_2: 0,
      faq_3: 0,
      faq_4: 0,
      faq_5: 0,
      email: "",
      password: "",
      isLoading: false
    };
    this.login = this.login.bind(this);
    this.formSubmit = this.formSubmit.bind(this);
  }

  LoaderStart = () => {
    this.setState({ isLoading: true });
  };
  LoaderStop = () => {
    this.setState({ isLoading: false });
  };
  handleInputChange = event => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.cheched : target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
  };
  async componentDidMount() {
    require("./assets/vendor/bootstrap/css/bootstrap.css");
    getAllSubscriptionPlansForHomePage().then(subscriptionsResponse => {
      if (subscriptionsResponse) {
        let subscriptions = subscriptionsResponse.data;
        this.setState({ subscriptions });
      }
    });

    const token = getToken();
    if (token != null) {
      this.props.history.push("/admin/Home");
    }
  }

  login = () => {
    if (this.validator.allValid()) {
      this.LoaderStart();
      login(this.state.email, this.state.password, this.state.is_checked)
        .then(json => {
          if (json != null && json.token && json.user) {
            saveToken(json.token);
            saveUserInfo(json.user);
            this.props.history.push("/admin/Home");
          } else {
          }
        })
        .finally(() => {
          this.LoaderStop();
        });
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  formSubmit = e => {
    e.preventDefault();
    this.login();
  };

  render() {
    return (
      <div className="homepage">
        <header id="header" class="fixed-top d-flex align-items-center">
          <div class="container d-flex align-items-center">
            <div class="logo mr-auto">
              <h1 class="text-light">
                <a href="index.html">
                  <span>Mileage2Go</span>
                </a>
              </h1>
              {/* <a href="index.html"><img src="assets/img/logo.png" alt="" class="img-fluid" /></a> */}
            </div>

            <nav class="nav-menu d-none d-lg-block">
              <ul>
                <li class="active">
                  <a href="#header">Home</a>
                </li>
                <li>
                  <a href="#pricing">Pricing</a>
                </li>
                <li>
                  <a href="#faq">FAQ</a>
                </li>
              </ul>
            </nav>
          </div>
        </header>

        <section id="hero">
          <div class="container">
            <div class="row">
              <div class="col-lg-7 pt-5 pt-lg-0 order-2 order-lg-1 d-flex align-items-center">
                <div>
                  <img
                    data-aos="fade-up"
                    data-aos-delay="100"
                    src={logo}
                    alt="Mileage2go"
                    class="img-fluid"
                  />
                  <h1
                    style={{
                      fontWeight: "normal",
                      fontFamily: "Source Sans Pro",
                      fontSize: "20"
                    }}
                  >
                    Mileage2Go uses your <b>Google Calendar</b> to Calculate
                    your mileage!
                  </h1>
                </div>
              </div>

              <div
                class="col-lg-5 order-1 order-lg-2  ml-auto align-items-left"
                data-aos="zoom-out"
                data-aos-delay="300"
              >
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div className="auth-wrapper">
                    <div className="auth">
                      <h3>Sign In</h3>

                      <div style={justifyCenter}>
                        <GoogleButton />
                        <br></br>
                      </div>

                      <br />
                      <PageSplitter backgroundColor="#f7f7f7" />
                      <br />
                      <form onSubmit={this.formSubmit}>
                        <div className="form-group">
                          <label>Email address</label>
                          <input
                            id="login_emails"
                            type="email"
                            className="form-control"
                            placeholder="Enter email"
                            value={this.state.email}
                            onChange={this.handleInputChange}
                            name="email"
                          />
                          {this.validator.message(
                            "email",
                            this.state.email,
                            "required|email"
                          )}
                        </div>

                        <div className="form-group">
                          <label style={{ width: "100%" }}>
                            Password{" "}
                            <span
                              style={{ float: "right" }}
                              className="forgot-password text-right"
                            >
                              <Link to={"/forgetpassword"}>
                                {" "}
                                Forgot password?
                              </Link>
                            </span>
                          </label>
                          <input
                            id="login_password"
                            type="password"
                            className="form-control"
                            placeholder="Enter password"
                            value={this.state.password}
                            onChange={this.handleInputChange}
                            name="password"
                          />
                          {this.validator.message(
                            "password",
                            this.state.password,
                            "required|string"
                          )}
                        </div>
                        <br />
                        <div style={justifyCenter}>
                          <Button
                            id="login"
                            disabled={this.state.isLoading}
                            //onClick={this.login}
                            loader={this.state.isLoading}
                            text={"Login"}
                            type={"submit"}
                          />
                        </div>
                      </form>
                      <br />
                      <PageSplitter backgroundColor="#f7f7f7" />
                      <br />
                      <SignupBody />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <svg
            class="hero-waves"
            style={{
              marginTop: 0
            }}
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            viewBox="0 24 150 28 "
            preserveAspectRatio="none"
          >
            <defs>
              <path
                id="wave-path"
                d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
              />
            </defs>
            <g class="wave1">
              <use
                xlinkHref="#wave-path"
                x="50"
                y="3"
                fill="rgba(255,255,255, .1)"
              />
            </g>
            <g class="wave2">
              <use
                xlinkHref="#wave-path"
                x="50"
                y="0"
                fill="rgba(255,255,255, .2)"
              />
            </g>
            <g class="wave3">
              <use xlinkHref="#wave-path" x="50" y="9" fill="#fff" />
            </g>
          </svg>
        </section>

        <section
          id="about"
          class="about"
          style={{
            background: "white"
          }}
        >
          <div class="container">
            <div class="row content">
              <div class="col-md-4" data-aos="fade-right">
                <img src={one} class="img-fluid" alt="" />
              </div>
              <div class="col-md-8 pt-4" data-aos="fade-up">
                <h3>1. Connect your Calendar.</h3>
                <br />
                <br />
                <br />
                <p>
                  Import your google calendar either at homepage or after
                  getting inside. You can also import an excel file using a
                  sample excel file provided.
                </p>
              </div>
            </div>

            <br />
            <br />
            <br />

            <div class="row content">
              <div class="col-md-8 pt-5 order-2 order-md-1" dataAos="fade-up">
                <h3>2. Select your timeframe.</h3>
                <br />
                <br />
                <br />

                <p>Mileage2Go allows you to choose the time period you need.</p>
              </div>
              <div class="col-md-4 order-1 order-md-2" dataAos="fade-left">
                <img src={two} class="img-fluid" alt="" />
              </div>
            </div>
            <br />
            <br />
            <br />

            <div class="row content">
              <div class="col-md-4" dataAos="fade-right">
                <img src={three} class="img-fluid" alt="" />
              </div>
              <div class="col-md-8 pt-5" dataAos="fade-up">
                <h3>3. Enter Where You are Driving From. </h3>
                <br />
                <br />
                <br />
                <p>
                  Mileage2Go calculates your mileage from where you start to
                  where you finish.{" "}
                </p>
              </div>
            </div>
            <br />
            <br />
            <br />

            <div class="row content">
              <div class="col-md-8 pt-5 order-2 order-md-1" dataAos="fade-up">
                <h3>4. Review and Analyze Entries.</h3>
                <br />
                <br />
                <br />

                <p>
                  Mileage2Go recreates your calendar so you can furthur refine
                  before you download your report.
                </p>
              </div>
              <div class="col-md-4 order-1 order-md-2" dataAos="fade-left">
                <img src={four} class="img-fluid" alt="" />
              </div>
            </div>
            <br />
            <br />
            <br />

            <div class="row content">
              <div class="col-md-4" dataAos="fade-right">
                <img src={five} class="img-fluid" alt="" />
              </div>
              <div class="col-md-8 pt-4" dataAos="fade-up">
                <h3>
                  5. Get Your Mileage! (You can download reports in Excel and
                  PDF)
                </h3>
                <br />
                <br />
                <br />

                <p>
                  Just a single click! Your calendar report along with the
                  mileage calculated will be sitting on your desktop either as
                  csv or as pdf. Mileage2Go is a great tool to calculate mileage
                  for tax deductions and for expense reports.
                </p>
              </div>
            </div>
            <br />
            <br />
            <br />
          </div>
        </section>
        <section id="pricing" class="pricing">
          <div class="container">
            <div class="section-title" dataAos="fade-up">
              <h2>Pricing</h2>
              <p>Pricing</p>
            </div>
            <div class="row" dataAos="fade-left">
              {this.state.subscriptions.map((subscription, index) => {
                if (index > 2) return;
                return (
                  <div class="col-lg-3 col-md-6 mt-4 mt-md-0">
                    <br />
                    <div
                      class="box featured"
                      dataAos="zoom-in"
                      dataAosDelay="100"
                    >
                      <h3>
                        {subscription.plan_name}
                      </h3>
                      <h4>
                        <sup>{subscription.currency_name}</sup>
                        {Number(subscription.plan_cost).toFixed(2)}
                      </h4>
                      <ul>
                        <li>Select time</li>
                      </ul>
                      <div class="btn-wrap">
                        <a href="#" class="btn-buy">
                          Buy Now
                        </a>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </section>

        <section id="faq" class="faq section-bg">
          <div class="container">
            <div class="section-title" dataAos="fade-up">
              <h2>F.A.Q</h2>
              <p>Frequently Asked Questions</p>
            </div>

            <div class="faq-list">
              <ul>
                <li dataAos="fade-up">
                  <i class="bx bx-help-circle icon-help"></i>
                  <a
                    onClick={e => {
                      e.preventDefault();
                      this.setState({
                        faq_1: !this.state.faq_1
                      });
                    }}
                    dataToggle="collapse"
                    class={this.state.faq_1 ? "collapse" : "collapsed"}
                    href="#faq-list-1"
                  >
                    Should I have to import all the data from my calendar?
                    <i class="bx bx-chevron-down icon-show"></i>
                    <i class="bx bx-chevron-up icon-close"></i>
                  </a>
                  <div
                    id="faq-list-1"
                    class={"collapse " + (this.state.faq_1 ? "show" : "")}
                    data-parent=".faq-list"
                  >
                    <p>
                      No, It is not needed. You can specify date range to be
                      imported.
                    </p>
                  </div>
                </li>

                <li dataAos="fade-up" dataAosDelay="100">
                  <i class="bx bx-help-circle icon-help"></i>
                  <a
                    onClick={e => {
                      e.preventDefault();
                      this.setState({
                        faq_2: !this.state.faq_2
                      });
                    }}
                    dataToggle="collapse"
                    href="#faq-list-2"
                    class={this.state.faq_2 ? "collapse" : "collapsed"}
                  >
                    Is the service free?
                    <i class="bx bx-chevron-down icon-show"></i>
                    <i class="bx bx-chevron-up icon-close"></i>
                  </a>
                  <div
                    id="faq-list-2"
                    class={"collapse " + (this.state.faq_2 ? "show" : "")}
                    dataParent=".faq-list"
                  >
                    <p>
                      You can select any one week of calendar entries for free to try out this service. You’ll be able to download a report which will show the mileage calculated.
                    </p>
                  </div>
                </li>

                <li dataAos="fade-up" dataAosDelay="200">
                  <i class="bx bx-help-circle icon-help"></i>
                  <a
                    onClick={e => {
                      e.preventDefault();
                      this.setState({
                        faq_3: !this.state.faq_3
                      });
                    }}
                    dataToggle="collapse"
                    href="#faq-list-3"
                    class={this.state.faq_3 ? "collapse" : "collapsed"}
                  >
                    Can i download the data which i exported eariler?
                    <i class="bx bx-chevron-down icon-show"></i>
                    <i class="bx bx-chevron-up icon-close"></i>
                  </a>
                  <div
                    id="faq-list-3"
                    class={"collapse " + (this.state.faq_3 ? "show" : "")}
                    dataParent=".faq-list"
                  >
                    <p>
                      Your exports are saved under export history. You can
                      download or delete export history.
                    </p>
                  </div>
                </li>

                <li dataAos="fade-up" dataAosDelay="300">
                  <i class="bx bx-help-circle icon-help"></i>
                  <a
                    onClick={e => {
                      e.preventDefault();
                      this.setState({
                        faq_4: !this.state.faq_4
                      });
                    }}
                    data-toggle="collapse"
                    href="#faq-list-4"
                    class={this.state.faq_4 ? "collapse" : "collapsed"}
                  >
                    How can I edit data in my calendar?{" "}
                    <i class="bx bx-chevron-down icon-show"></i>
                    <i class="bx bx-chevron-up icon-close"></i>
                  </a>
                  <div
                    id="faq-list-4"
                    class={"collapse " + (this.state.faq_4 ? "show" : "")}
                    dataParent=".faq-list"
                  >
                    <p>
                      When you import we will provided a grid to modify the
                      data.
                    </p>
                  </div>
                </li>

                <li dataAos="fade-up" dataAosDelay="400">
                  <i class="bx bx-help-circle icon-help"></i>
                  <a
                    onClick={e => {
                      e.preventDefault();
                      this.setState({
                        faq_5: !this.state.faq_5
                      });
                    }}
                    dataToggle="collapse"
                    href="#faq-list-5"
                    class={this.state.faq_5 ? "collapse" : "collapsed"}
                  >
                    I don't use google calendar! How can I import my data?
                    <i class="bx bx-chevron-down icon-show"></i>
                    <i class="bx bx-chevron-up icon-close"></i>
                  </a>
                  <div
                    id="faq-list-5"
                    class={"collapse " + (this.state.faq_5 ? "show" : "")}
                    dataParent=".faq-list"
                  >
                    <p>You can import your data with Excel format.</p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </section>

        <footer id="footer">
          <div class="footer-top">
            <div class="container">
              <div class="row">
                <div class="col-lg-4 col-md-6">
                  <div class="footer-info">
                    <h3>Mileage2Go</h3>
                    <p class="pb-3">
                      <em>
                        Mileage2Go is a great tool to calculate mileage for tax
                        deductions and for expense reports.
                      </em>
                    </p>
                    <br />
                    <p>
                      <strong>Address: </strong> <br />
                      269 South Beverly Drive Beverly Hills, <br />
                      CA 90212, USA <br />
                      <strong>Phone:</strong> +1 888-543-4420 <br />
                      <strong>Email:</strong> support@mileage2go.com
                    </p>
                  </div>
                </div>

                <div class="col-lg-2 col-md-6 footer-links">
                  <h4>Useful Links</h4>
                  <ul>
                    <li>
                      <i class="bx bx-chevron-right"></i>{" "}
                      <a href={BASE_URL + "../docs/terms_of_service.html"}>
                        Terms of service
                      </a>
                    </li>
                    <li>
                      <i class="bx bx-chevron-right"></i>{" "}
                      <a href={BASE_URL + "../docs/privacy.html"}>
                        Privacy policy
                      </a>
                    </li>
                  </ul>
                </div>

                <div class="col-lg-4 col-md-6 footer-newsletter">
                  <img src={about} class="img-fluid" alt="" />
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    );
  }
}
