import { BASE_URL } from "./../configs/Constants";
import {
  getToken
} from "../utils/utilities";
import { post, put, get } from "./fetch_requests";


export const login = async (email, password, remember_me) => {
  const header = new Headers();
  header.append("Content-Type", "application/json");
  let body = {
    email,
    password,
    remember_me
  };
  return post(`${BASE_URL}auth/login`, header, body);
};

export const loginWithGoogle = response => {
  const header = new Headers();
  header.append("Content-Type", "application/json");
  let body = {
    id_token: response.tokenId,
    access_token: response.accessToken,
    created_at: response.tokenObj.first_issued_at,
    expires_in: response.tokenObj.expires_in
  };
  return post(`${BASE_URL}auth/google`, header, body);
};

export const register = async (email, password, name) => {
  const header = new Headers();
  header.append("Content-Type", "application/json");
  let body = {
    email,
    password,
    name
  };
  return post(`${BASE_URL}auth/register`, header, body);
};

export const forgetPassword = async email => {
  const header = new Headers();
  header.append("Content-Type", "application/json");
  let body = {
    email
  };
  return post(`${BASE_URL}auth/forgetPassword`, header, body);
};

export const resetPassword = async (password, token) => {
  const header = new Headers();
  header.append("Content-Type", "application/json");
  let body = {
    password,
    token
  };
  return post(`${BASE_URL}auth/resetPassword`, header, body);
};

export const updatePassword = async (oldPassword, newPassword) => {
  const header = new Headers();
  let token = getToken();
  header.append("Content-Type", "application/json");
  header.append("Authorization", `Bearer ${token}`);
  let body = {
    oldPassword,
    newPassword
  };
  return put(`${BASE_URL}update-password`, header, body);
};

export const userProfile = async (name, color, image) => {
  const header = new Headers();
  let token = getToken();
  header.append("Content-Type", "application/json");
  header.append("Authorization", `Bearer ${token}`);
  let body = {
    name,
    color,
    image
  };
  return put(`${BASE_URL}update-myprofile`, header, body);
};

export const userInfo = () => {
  const header = new Headers();
  let token = getToken();
  header.append("Authorization", `Bearer ${token}`);
  return get(`${BASE_URL}user`, header);
};
