import {
    BASE_URL
  } from "./../configs/Constants";
  import { 
    normalizeResponseErrors, 
    fetchErrorHandler,
    getToken
  } from "../utils/utilities";
  
  // TODO: Why profile requests are in auth file? 
export const getBackgroundImages = () => {
    const myHeaders = new Headers();
    let myToken = getToken();
    myHeaders.append("Authorization", `Bearer ${myToken}`);
    return fetch(`${BASE_URL}images`, {
        method: "GET",
        headers: myHeaders
    })
    .then(res => normalizeResponseErrors(res))
    .catch(err => fetchErrorHandler(err));
};
