import React from "react";
import useFetch, { CachePolicies } from "use-http";

export function TotalCreditPoints() {
  const { loading, error, data = { data: 0 } } = useFetch(
    "/show-credit-points",
    {},
    []
  );
  return <h3>Total Credit Points : {data.data || 0}</h3>;
}
