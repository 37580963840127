import React, { Component } from "react";

class CustomRadioButton extends Component {
  render() {
    const { id, label, text, option, name, ...rest } = this.props;
    return (
      <div
        className="radio"
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center"
        }}
      >
        <input id={id} name={name} type="radio" value={option} {...rest} />
        <label style={{
          fontSize: 15
        }} htmlFor={id}><span style={{
          margin: 5
        }}>{text}</span></label>
      </div>
    );
  }
}

export default CustomRadioButton;
