import React from "react";
import { Modal } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import BaseClass from "./../BaseClass";
import Button from "./../Button/CustomButton";
import CustomColorButton from "./../Button/CustomColorButton";
import { saveUserInfo, getUserInfo } from "./../../utils/utilities";

import "./../App.css";
import { userProfile } from "actions/auth";
import BackgroundImages from "components/SidebarImages/SidebarImages";
import { getBackgroundImages } from "actions/profile";

class UserProfile extends BaseClass {
  constructor(props) {
    super(props);
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.UserProfileUpdate = this.UserProfileUpdate.bind(this);

    this.state = {
      name: "",
      show: false,
      classes: "dropdown show-dropdown open",
      bg_checked: true,
      bgImage: this.props.bgImage,
      color: "",
      hasImage: true,
      fixedClasses: "dropdown show-dropdown open", 
      backgroundImages: []
    };
  }
  devFill = () => {
    try{
      let { image, color, name } = getUserInfo();
        this.props.handleColorClick(color);
        this.props.handleImageClick(image);
        this.setState({ name: name, color: color, bgImage: image }, () => {});
    }catch(er)
    {
      window.location.href = "/"
    }

  };

  getProfileOptions = async () => {
    getBackgroundImages().then((data)=>{
      if(data && data.data){
        this.setState({
          backgroundImages: data.data
        })
      }
    })
  }

  componentDidMount = () => {
    this.devFill();
    this.getProfileOptions();
  };
  handleClose = () => {
    this.setState({ show: false });
  };

  handleShow = () => {
    this.setState({ show: true });
  };
  handleClick = () => {
    this.handleFixedClick();
  };
  onChangeClick = () => {
    this.props.handleHasImage(!this.state.bg_checked);
    this.setState({ bg_checked: !this.state.bg_checked });
  };
  handleFixedClick = () => {
    if (this.state.fixedClasses === "dropdown") {
      this.setState({ fixedClasses: "dropdown show-dropdown open" });
    } else {
      this.setState({ fixedClasses: "dropdown" });
    }
  };
  UserProfileUpdate = () => {
    this.LoaderStart();
    userProfile(this.state.name, this.state.color, this.state.bgImage)
      .then(json => {
        if (json && json.status === "SUCCESS") {
          this.setState({ name: json.data.name, color: json.data.color });
          saveUserInfo(json.data);
        }                                                                                                                                                            
      })

      .finally(() => {
        this.LoaderStop();
        this.handleClose();
      });
  };
  RestoreChanges = () => {
    this.devFill();
    this.handleClose();
  };
  UpdateState = (name, value) => {
    this.setState({
      [name]: value
    });
  };

  handleImageClick = (image) => {
    this.setState({
      bgImage: image
    })
    this.props.handleImageClick(image);
  }

  render() {
    return (
      <div>
        <Modal
          centered
          onExit={this.RestoreChanges}
          show={this.state.show}
          onHide={this.handleClose}
          style={{
            zIndex: 1500
          }}
        >
          <Modal.Header closeButton >
            <Modal.Title> My Profile</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <table style={{
              border: "100px",
              width: "100%"
            }}>
            <tr>
            <div className="form-group">
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center"
                }}
              >
                <th>
                <div>
                  <h4>Name :&nbsp;&nbsp;</h4>
                </div>
                </th>
                <th>
                <div
                  style={{
                    marginRight: "35px",
                    marginLeft: "130px"
                  }}
                >
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Name"
                    value={this.state.name}
                    onChange={this.handleInputChange}
                    name="name"
                  />
                  {this.validator.message(
                    "password",
                    this.state.opassword,
                    "required|string"
                  )}
                </div>
                </th>
              </div>
            </div>
            </tr>
            <div>
              <br />
              <br />
              <tr>
                <th>
              <div>

                <h4>Theme :</h4>
              </div>
              </th>
              <th>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center"
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    marginLeft: "130px"
                  }}
                >
                  <div style={{ marginRight: 50 }}>
                    <CustomColorButton
                      backgroundColor={"red"}
                      onClick={() => {
                        this.props.handleColorClick("red");
                        this.UpdateState("color", "red");
                      }}
                    />
                  </div>
                  <div style={{ marginRight: 50 }}>
                    <CustomColorButton
                      backgroundColor={"black"}
                      onClick={() => {
                        this.props.handleColorClick("black");
                        this.UpdateState("color", "black");
                      }}
                    />
                  </div>
                  <div>
                    <CustomColorButton
                      borderColor={"#0074ff"}
                      backgroundColor={"azure"}
                      onClick={() => {
                        this.props.handleColorClick("azure");
                        this.UpdateState("color", "azure");
                      }}
                    />
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    marginTop: "20px",
                    marginLeft: "130px",
                  }}
                >
                  <div style={{ marginRight: 50 }}>
                    <CustomColorButton
                      backgroundColor={"green"}
                      onClick={() => {
                        this.props.handleColorClick("green");
                        this.UpdateState("color", "green");
                      }}
                    />
                  </div>
                  <div style={{ marginRight: 50 }}>
                    <CustomColorButton
                      backgroundColor={"orange"}
                      onClick={() => {
                        this.props.handleColorClick("orange");
                        this.UpdateState("color", "orange");
                      }}
                    />
                  </div>
                  <div>
                    <CustomColorButton
                      backgroundColor={"purple"}
                      onClick={() => {
                        this.props.handleColorClick("purple");
                        this.UpdateState("color", "purple");
                      }}
                    />
                  </div>
                </div>
              </div>
              </th>
              </tr>
              <div>
                <br />
                <br />
                <tr>
                  <th>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "left"
                  }}
                >
                  <div>
                    <h4 className="pull-left">Background Image:</h4>
                  </div>
                </div>
                </th>
                <th>
                <ul
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    listStyleType: "none"
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      marginLeft: "0px"
                    }}
                  >
                    <BackgroundImages backgroundImages={this.state.backgroundImages}
                      handleImageClick={this.handleImageClick}
                      />
                  </div>
                </ul>
                </th>
                </tr>
              </div>
            </div>
            </table>
          </Modal.Body>
          <Modal.Footer>
          <div style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end"
          }}>
            <Button
              loader={this.state.isLoading}
              disabled={this.state.isLoading}
              width={"80px"}
              text={"Save"}
              onClick={() => {
                this.UserProfileUpdate();
              }}
            />
            <Button
              width={"80px"}
              text={"Cancel"}
              onClick={() => {
                this.RestoreChanges();
              }}
            />
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default UserProfile;
