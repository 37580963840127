import React, { Component } from "react";
import ReactDOM from "react-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/animate.min.css";
import "./assets/sass/light-bootstrap-dashboard-react.scss?v=1.3.0";
import "./assets/css/demo.css";
import "./assets/css/pe-icon-7-stroke.css";
import "./custom.css";
import App from "./App";
import { toast } from "react-toastify";
import "react-dates/lib/css/_datepicker.css";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import "react-dates/initialize";
import useFetch, { CachePolicies, Provider } from "use-http";
import { BASE_URL } from "configs/Constants";
import { getToken } from "utils/utilities";
import { render } from "react-snapshot";

const options = {
  interceptors: {
    request: async ({ options, url, path, route }) => {
      const token = getToken();
      options.headers.Authorization = `Bearer ${token}`;
      return options;
    },
    // every time we make an http request, before getting the response back, this will run
    response: async ({ response }) => {
      return response;
    }
  },
  cachePolicy: CachePolicies.NO_CACHE
};

render(
  <Provider url={BASE_URL} options={options}>
    <App />
  </Provider>,
  document.getElementById("root")
);
toast.configure();
