import React, { Component } from "react";
import { Navbar } from "react-bootstrap";
import AdminNavbarLinks from "./AdminNavbarLinks.jsx";

import routes from "routes.js";

class AdminNavbar extends Component {
  getActiveRoute = routes => {
    let activeRoute = "Import";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = this.getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };
  mobileSidebarToggle = e => {
    document.documentElement.classList.toggle("nav-open");
    if (
      document.documentElement.className === "nav-open" &&
      this.props.fixedClasses !== "dropdown"
    ) {
      //this.props.handleFixedClick();
    }
  };
  render() {
    return (
      <Navbar
        fluid
        collapseOnSelect
        className={this.props.navbar ? "navbar-fixed" : ""}
        style={{
          position: "fixed",
          background: "#fff",
          right: 0,
          zIndex: 1200,
          height: "65px",
          width:  window.innerWidth > 992 ? "calc(100% - 340px)" : '100%' 
        }}
      >
        <Navbar.Header>
          <Navbar.Brand>
            <a href="#pablo">{this.getActiveRoute(routes)}</a>
          </Navbar.Brand>
          <Navbar.Toggle onClick={this.mobileSidebarToggle} />
        </Navbar.Header>
        
        {window.innerWidth > 992 ? (
          <Navbar.Collapse>
            <AdminNavbarLinks {...this.props} />
          </Navbar.Collapse>
        ) : null}
      </Navbar> 
    );
  }
}

export default AdminNavbar;
