import { BASE_URL } from "./../configs/Constants";
import {
  normalizeResponseErrors,
  fetchErrorHandler,
  getToken
} from "../utils/utilities";
import { get, post } from "./fetch_requests";

export const getSubscriptions = async () => {
  const header = new Headers();
  let token = getToken();
  header.append("Authorization", `Bearer ${token}`);
  return get(`${BASE_URL}subscription/list`, header);
};

export const createNewSubscription = async subscriptionDetails => {
  let token = getToken();
  const header = new Headers();
  header.append(`Content-Type`, `application/json`);
  header.append(`Authorization`, `Bearer ${token}`);
  const body = subscriptionDetails;
  return post(`${BASE_URL}subscription/newToken`, header, body);
};

export const activateTrialPeriod = async trialPeriodDetails => {
  let token = getToken();
  const header = new Headers();
  header.append(`Content-Type`, `application/json`);
  header.append(`Authorization`, `Bearer ${token}`);
  const body = trialPeriodDetails;
  return post(`${BASE_URL}subscription/activate-trial-period`, header, body);
};

export const subscriptionConfirmation = async subscriptionDetails => {
  let token = getToken();
  const header = new Headers();
  header.append(`Content-Type`, `application/json`);
  header.append(`Authorization`, `Bearer ${token}`);
  const body = subscriptionDetails;
  return post(`${BASE_URL}subscription/confirm`, header, body);
};

export const getAllInvoices = () => {
  let token = getToken();
  const header = new Headers();
  header.append(`Content-Type`, `application/json`);
  header.append(`Authorization`, `Bearer ${token}`);
  return get(`${BASE_URL}subscription/invoices`, header);
};

export const getAllSubscriptionList = () => {
  let token = getToken();
  const header = new Headers();
  header.append(`Content-Type`, `application/json`);
  header.append(`Authorization`, `Bearer ${token}`);
  return get(`${BASE_URL}subscription/mysubscriptions`, header);
};

export const getAllSubscriptionPlansForHomePage = async () => {
  const header = new Headers();
  header.append("Content-Type", "application/json");
  return get(`${BASE_URL}homepage/subscriptions/list`, header);
};
