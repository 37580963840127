import React from "react";
import {
  ElementsConsumer,
  CardElement,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import Button from "./../../components/Button/CustomButton"
import CardSection from "./CardSection";
import { subscriptionConfirmation } from "actions/subscriptions";

class CheckoutForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        loader: false
    }
  }
  handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();
    this.setState({
        loader: true
    })
    const { stripe, elements } = this.props;

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make  sure to disable form submission until Stripe.js has loaded.
      this.setState({
        loader: false
    })
      return;
    }

    const result = await stripe.confirmCardPayment(
      this.props.credentials.payment_intent_client_id,
      {
        payment_method: {
          card: elements.getElement(CardElement),
        },
      }
    );
    if (result.error) {
        this.setState({
            loader: false
        })
      // Show error to your customer (e.g., insufficient funds)
      console.log(result.error.message);
    } else {
      // The payment has been processed!
      if (result.paymentIntent.status === "succeeded") {
        // Show a success message to your customer
        // There's a risk of the customer closing the window before callback
        // execution. Set up a webhook or plugin to listen for the
        // payment_intent.succeeded event that handles any business critical
        // post-payment actions.

        let confirmSubscription = {
          payment_intent_id: this.props.credentials.payment_intent_id,
        };
        this.setState({
          isLoading: true,
        });
        subscriptionConfirmation(confirmSubscription).finally(() => {
          this.props.hideModal();
          this.setState({
            isLoading: false,
            loader: false
          });
          this.props.history.push("/admin/Invoice");
        });
      }
      this.props.hideModal();
    }
  };

  render() {
    //   return(
    //   <form onSubmit={this.handleSubmit}>
    //     <label>
    //       Card number
    //       <CardNumberElement />
    //     </label>
    //     <label>
    //       Expiration date
    //       <CardExpiryElement />
    //     </label>
    //     <label>
    //       CVC
    //       <CardCvcElement />
    //     </label>
    //     <button>Pay</button>
    //   </form>
    //   );
    return (
      <form onSubmit={this.handleSubmit}>
        <CardSection />
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button
            style={{
              borderRadius: 8,
              backgroundColor: "#239b56",
              color: "white",
              fontSize: 15,
              margin: 10,
              padding: 10,
            }}
            disabled={this.state.loader}
            text={'Confirm order'}
            loader={this.state.loader}
            onClick={this.handleSubmit}
          >
          </Button>
        </div>
      </form>
    );
  }
}

export default function InjectedCheckoutForm(props) {
  return (
    <ElementsConsumer>
      {({ stripe, elements }) => (
        <CheckoutForm {...props} stripe={stripe} elements={elements} />
      )}
    </ElementsConsumer>
  );
}
