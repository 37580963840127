import React, { Component } from "react";
import SimpleReactValidator from "simple-react-validator";

// TODO: Breaks SRP 
// TODO: A class should have single responsibility 
class BaseClass extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator();
    this.handleInputChange = this.handleInputChange.bind(this);
    this.LoaderStop = this.LoaderStop.bind(this);
    this.LoaderStart = this.LoaderStart.bind(this);
  }
  LoaderStart = () => {
    this.setState({ isLoading: true });
  };
  LoaderStop = () => {
    this.setState({ isLoading: false });
  };
  handleInputChange = event => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.cheched : target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
  };
 
}

export default BaseClass;
