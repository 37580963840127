import { BASE_URL } from "./../configs/Constants";
import {
  normalizeResponseErrors,
  fetchErrorHandler,
  getToken
} from "../utils/utilities";
import { get, Delete } from "./fetch_requests";

export const fetchCalEvents = async (month, year) => {
  const header = new Headers();
  let token = getToken();
  header.append("Authorization", `Bearer ${token}`);
  header.append("Content-Type", "application/json");
  return get(`${BASE_URL}my-events?month=${month}&year=${year}`, header);
};

export const getCalendarList = async () => {
  const header = new Headers();
  let token = getToken();
  header.append("Authorization", `Bearer ${token}`);
  header.append("Content-Type", "application/json");
  return get(`${BASE_URL}calendar/list`, header);
};

export const deleteEvent = async eventId => {
  const header = new Headers();
  let token = getToken();
  header.append("Authorization", `Bearer ${token}`);
  return Delete(`${BASE_URL}my-events/delete/${eventId}`, header);
};
