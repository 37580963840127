import { BASE_URL } from './../configs/Constants'
import {
    normalizeResponseErrors,
    fetchErrorHandler,
    getToken
} from "../utils/utilities";
import { post, postBodyNotStringify } from "./fetch_requests"

export const importFromGoogle = ({ tokenId, accessToken, tokenObj }, fromDate, toDate,homeLocation) => {

    let token = getToken() ;

    const header = new Headers();
    header.append(`Content-Type`, `application/json`) ;
    header.append(`Authorization`, `Bearer ${token}`) ;

    let body = {
        id_token: tokenId,
        access_token: accessToken,
        created_at: tokenObj.first_issued_at,
        expires_in: tokenObj.expires_in, 
        fromDate: fromDate, 
        toDate: toDate,
        from: homeLocation
    }

    return post(`${BASE_URL}import`, header, body);
}

export const importFromCsv = (formData) => {
    const url = `${BASE_URL}import/upload`;
    let token  = getToken();
    const header = new Headers();
    header.append( `Authorization`, `Bearer ${token}` );
    let body = formData
    return postBodyNotStringify(url, header, body);
}

export const validateImport = (fromDate, toDate) => {
    let token = getToken() ;

    const header = new Headers();
    header.append(`Content-Type`, `application/json`) ;
    header.append(`Authorization`, `Bearer ${token}`) ;

    let body = { fromDate, toDate }

    return post(`${BASE_URL}validateImport`, header, body);
}
