import React, { Component } from "react";

const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec"
];
export class MonthPicker extends Component {
  onChange = event => {
    if (this.props.changeState != null) {
      this.props.change(this.props.changeState, event);
    } else {
      this.props.change(event);
    }
  };

  render() {
    return (
      <select
        selected={this.props.selected}
        value={this.props.selected}
        onChange={event => this.onChange(event)}
      >
        {months.map((month, index) => {
          return <option value={index}>{month}</option>;
        })}
      </select>
    );
  }
}

export default MonthPicker;
