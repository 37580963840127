import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { Collapse } from "react-bootstrap";
import AdminNavbarLinks from "../Navbars/AdminNavbarLinks.jsx";
import logo from "assets/img/logo-transparent-200-200.png";
import { BASE_URL } from "configs/Constants.jsx";

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: window.innerWidth
    };
  }
  activeRoute = routeName => {
    return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  };
  updateDimensions = () => {
    this.setState({ width: window.innerWidth });
  };
  componentDidMount = () => {
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions.bind(this));
  };

  getCollapseInitialState = routes => {
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse && this.getCollapseInitialState(routes[i].views)) {
        return true;
      } else if (window.location.href.indexOf(routes[i].path) !== -1) {
        return true;
      }
    }
    return false;
  };
  openHelpDocument = route => {
    let params = `width=0,height=0,left=-1000,top=-1000`;
    let url = `${BASE_URL}../help/?page=${route}`
    window.open(url,"Mileage2Go-Help",params);
  }

  createLinks = routes => {
    return routes.map((prop, key) => {
      if (prop.redirect) {
        return null;
      }
      if (prop.collapse) {
        var st = {};
        st[prop["state"]] = !this.state[prop.state];
        return (
          <li
            className={this.getCollapseInitialState(prop.views) ? "active" : ""}
            key={key}
          >
            <a
              href="#pablo"
              onClick={e => {
                e.preventDefault();
                this.setState(st);
              }}
            >
              <i className={prop.icon} />
              <p style={{
                  fontSize: 20
                }}>
                {prop.name}
                <b
                  className={
                    this.state[prop.state] ? "caret rotate-180" : "caret"
                  }
                />
              </p>
            </a>
            <Collapse in={this.state[prop.state]}>
              <ul
                className="nav"
                style={{
                  marginLeft: 30
                }}
              >
                {this.createLinks(prop.views)}
              </ul>
            </Collapse>
          </li>
        );
      }
      return (
        <li className={this.activeRoute(prop.layout + prop.path)} key={key}>
          <NavLink
            to={prop.layout + prop.path}
            className="nav-link"
            activeClassName="active"
          >
            {prop.icon ? (
              <>
                <i className={prop.icon} />
                <i className={'pe-7s-help1'} 
                title="Open help manual"
                style={{
                  marginRight: 0,
                  float: 'right',
                  fontSize: 20
                }} onClick={()=>{
                  this.openHelpDocument(prop.path)
                }} />
                <p style={{
                  fontSize: 20
                }}>{prop.name}</p>
              </>
            ) : (
              <>
                <span className="sidebar-normal">{prop.name}</span>
              </>
            )}
          </NavLink>
        </li>
      );
    });
  };

  render() {
    const sidebarBackground = {
      backgroundImage: "url(" + this.props.image + ")"
    };
    return (
      <div
        id="sidebar"
        className="sidebar"
        data-color={this.props.color}
        data-image={this.props.image}
      >
        {this.props.hasImage ? (
          <div className="sidebar-background" style={sidebarBackground} />
        ) : null}
        <div
          className="logo"
          style={{
            border: "none",
            display: "flex",
            flexDirection: "row",
            alignSelf: "center",
            textAlign: "center"
          }}
        >
          <a className="simple-text logo-mini">
            <div className="logo-img">
              <img
                style={{
                  width: 180,
                  marginLeft: 50
                }}
                src={logo}
                alt="logo_image"
              />
            </div>
          </a>
        </div>

        <div className="sidebar-wrapper">
          <ul
            className="nav"
            style={{
              marginTop: 100
            }}
          >
            {this.state.width <= 991 ? <AdminNavbarLinks {...this.props} /> : null}
            {this.createLinks(this.props.routes)}
          </ul>
        </div>
      </div>
    );
  }
}

export default Sidebar;
