import React, { Component, useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { get } from "actions/fetch_requests";
import { BASE_URL } from "configs/Constants";
import { getWithAbort } from "actions/fetch_requests";
import useFetch, { CachePolicies } from "use-http";

// export default class LocationAccurancy extends Component {
//   abortFetch = null;
//   constructor() {
//     super();
//     this.state = {
//       loading: true,
//       address: "",
//       color: "green",
//       status: "",
//     };
//   }
//   getColorCode = (status) => {
//     switch (status) {
//       case "NOT FOUND":
//         return "#B00020";

//       case "APPROXIMATE":
//         return "#9b870c";
//       case "GENERIC":
//         return "blue";
//       case "GOOD":
//         return "green";
//     }
//     return "";
//   };
//   componentWillUnmount() {
//     if (this.abortFetch != null) {
//       //cancel the request
//       this.abortFetch();
//     }
//   }
//   componentDidMount() {
//     if (this.props.value != "") {
//       const [promise, abort] = getWithAbort(
//         `${BASE_URL}import/validate-address?address=` +
//           encodeURI(this.props.value)
//       );
//       this.abortFetch = abort;
//       promise
//         .then(({ data }) => {
//           console.log(data);
//           this.setState({
//             loading: false,
//             address: data.address,
//             status: data.status,
//             color: this.getColorCode(data.status),
//           });
//         })
//         .catch((err) => {})
//         .finally((e) => {
//           this.abortFetch = null;
//         });
//     } else {
//       this.setState({
//         loading: false,
//         address: "Address Not Found",
//         status: "NOT FOUND",
//         color: this.getColorCode("NOT FOUND"),
//       });
//     }
//     // let data = this.props.value
//     // this.setState({
//     //     loading: false,
//     //     address: data.address,
//     //     status: data.status,
//     //     color: this.getColorCode(data.status)
//     // })
//   }
//   render() {
//     if (this.state.loading) return <div>Validating Address...</div>;

//     return (
//       <div>
//         <Button
//           style={{
//             background: this.state.color,
//             color: "white",
//             width: 115,
//             textAlign: "center",
//           }}
//         >
//           {this.state.status}
//         </Button>
//         <br />
//         {this.state.address}
//       </div>
//     );
//   }
// }

export default function LocationAccurancy({ value }) {
  const [locationAccuracy, setLocationAccuracy] = useState({
    address: "",
    color: "green",
    status: ""
  });
  const getColorCode = status => {
    switch (status) {
      case "NOT FOUND":
        return "#B00020";

      case "APPROXIMATE":
        return "#9b870c";
      case "GENERIC":
        return "blue";
      case "GOOD":
        return "green";
    }
    return "";
  };

  const { data, get, loading, abort } = useFetch(
    `${BASE_URL}import/validate-address?address=` + encodeURI(value),
    {
      onError: () => {
        setLocationAccuracy({
          address: "Address Not Found",
          status: "NOT FOUND",
          color: getColorCode("NOT FOUND")
        });
      },
      cachePolicy: CachePolicies.CACHE_FIRST
    }
  );

  useEffect(() => {
    if (value) {
      get();
    }
    return () => {
      abort();
    };
  }, []);

  useEffect(() => {
    if (data?.data) {
      setLocationAccuracy({
        address: data?.data?.address,
        status: data?.data.status,
        color: getColorCode(data?.data.status)
      });
    } else {
      setLocationAccuracy({
        address: "Address Not Found",
        status: "NOT FOUND",
        color: getColorCode("NOT FOUND")
      });
    }
  }, [data]);

  if (loading) {
    return <div>Validating Address...</div>;
  } else {
    return (
      <div>
        <Button
          style={{
            background: locationAccuracy.color,
            color: "white",
            width: 115,
            textAlign: "center"
          }}
        >
          {locationAccuracy.status}
        </Button>
        <br />
        {locationAccuracy.address}
      </div>
    );
  }
}
