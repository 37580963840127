import React from "react";
import { withRouter } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import Button from "components/Button/CustomButton";
import { register } from "../../actions/auth";
import {
  saveToken, 
  saveUserInfo
} from './../../utils/utilities' ;
import { DEV } from "../../configs/Constants";
import BaseClass from "../BaseClass";
// TODO: Component should be independent of everything (No Extension) 
class SignupBody extends BaseClass {
  // TODO: No need for a separate component 
  constructor(props) {
    super();
    this.state = {
      name: "",
      email: "",
      password: "",
      isLoading: false
    };
    this.register = this.register.bind(this);
  }
  devFill = () => {
    if (DEV) {
      this.setState({
        name: "",
        email: "",
        password: ""
      });
    }
  };
  formSubmit = e =>{
    e.preventDefault();
    this.register();
  }

  register = () => {
    if (this.validator.allValid()) {
      this.LoaderStart();
      register(this.state.email, this.state.password, this.state.name)
        .then(json => {
          if (json != null && json.token && json.user) {
            saveToken(json.token);
            saveUserInfo(json.user);
            this.props.history.push("/admin/home");
          }
        })
        .finally(() => {
          this.LoaderStop();
        });
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  render() {
    return (
      <div>
        <h3 id="heading">Sign Up</h3>
        <form onSubmit={this.formSubmit}>
        <div className="form-group">
          <label>Name</label>
          <input
            id="name"
            type="text"
            className="form-control"
            placeholder="Name"
            value={this.state.name}
            onChange={this.handleInputChange}
            name="name"
          />
          {this.validator.message("name", this.state.name, "required|string")}
        </div>

        <div className="form-group">
          <label>Email address</label>
          <input
            id="email"
            type="email"
            className="form-control"
            placeholder="Enter email"
            value={this.state.email}
            onChange={this.handleInputChange}
            name="email"
          />
          {this.validator.message("email", this.state.email, "required|email")}
        </div>

        <div className="form-group">
          <label>Password</label>
          <input
            id="password"
            type="password"
            className="form-control"
            placeholder="Enter password"
            value={this.state.password}
            onChange={this.handleInputChange}
            name="password"
          />
          {this.validator.message(
            "password",
            this.state.password,
            "required|string"
          )}
        </div>
        <br />
        <div
          style={{
            display: "flex",
            justifyContent: "center"
          }}
        >
          <Button
            id="signup"
            loader={this.state.isLoading}
            disabled={this.state.isLoading}
            // onClick={this.register}
            text="Sign Up"
            type={"submit"}
          />
          <br />
        </div>
        </form>
      </div>
    );
  }
}

export default withRouter(SignupBody);
