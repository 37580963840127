import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import AdminLayout from "./layouts/Admin.jsx";
import Login from "./views/auth/Login";
import SignUp from "./views/auth/Signup";
import ForgetPassword from "./views/auth/ForgetPassword";
import ResetPassword from "./views/auth/ResetPassword";
import "react-datasheet/lib/react-datasheet.css";
import HomePage from "views/HomePage/Second/HomePage.js";

const App = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={HomePage} />
        <Route exact path="/index.html" component={HomePage} />
        {/* <Route exact path="/homepage" component={HomePage} /> */}
        {/* <Route exact path="/register" component={SignUp} /> */}
        <Route exact path="/forgetpassword" component={ForgetPassword} />
        <Route
          exact
          path="/auth/resetPassword/:token"
          component={ResetPassword}
        />
        <Route path="/admin" render={props => <AdminLayout {...props} />} />
        <Redirect from="/home.html" to="/" />
        <Redirect from="/home" to="/" />
        <Redirect from="/login" to="/" />
      </Switch>
    </BrowserRouter>
  );
};
export default App;
