import React from "react";
import { Grid, Modal, Button } from "react-bootstrap";
import dates, { week } from "react-big-calendar/lib/utils/dates";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import Colors from "./../configs/Colors";
import {
  CustomCalendarModal,
  CustomConfirmationModal
} from "components/CustomModal";
import {
  fetchCalEvents,
  getCalendarList,
  deleteEvent
} from "./../actions/calendar";
import { CustomCard } from "components";
const localizer = momentLocalizer(moment);
export default class Maps extends React.Component {
  CustomCalendarModalRef = null;
  CustomConfirmationModalRef = null;

  constructor(props) {
    super(props);
    // this.onNavigate = this.onNavigate.bind(this);
    this.state = {
      calendarlist: [],
      events: [],
      currentMonth: "",
      currentYear: "",
      currentEventTitle: "",
      currentEventDescription: "",
      currentEventStart: "",
      currentEventEnd: "",
      currentEventId: "",
      currentLocation: "",
      currentIsExportable: ""
    };

    this.deleteEvent = this.deleteEvent.bind(this);
    this.confirmation = this.confirmation.bind(this);
    this.showConfirmationDialog = this.showConfirmationDialog.bind(this);
  }

  // onNavigate = (date, view, action) => {
  //   console.log(dates.firstVisibleDay(date), dates.lastVisibleDay(date));
  // };
  calendarRef = React.createRef();

  componentDidMount = () => {
    this.calendarList();

    let date = new Date();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    this.getEvents(month, year);
    this.setState({
      currentMonth: month,
      currentYear: year
    });
  };

  eventStyleGetter = (event, start, end, isSelected) => {
    var backgroundColor = event.hexColor;
    var color = event.textColor;
    var style = {
      backgroundColor: backgroundColor,
      borderRadius: "0px",
      opacity: 0.8,
      color: color,
      border: "0px",
      display: "block"
    };
    return {
      style: style
    };
  };

  calendarList = () => {
    getCalendarList().then(json => {
      if (json && json.status === "SUCCESS") {
        this.setState({
          calendarlist: json.data
        });
      }
    });
  };

  getEvents = (month, year) => {
    fetchCalEvents(month, year).then(json => {
      if (json && json.status === "SUCCESS") {
        let events = [];
        let data = json.data;
        data.map((item, index) => {
          events.push({
            title: item.list_calendar_title,
            start: item.start_date,
            end: item.end_date,
            hexColor: item.calendar.background_color,
            textColor: item.calendar.foreground_color,
            description: item.description,
            eventId: item.event_id,
            location: item.location,
            is_exportable: item.is_exportable
          });
        });

        this.setState({
          events
        });
      }
    });
  };

  selectEvents = cal => {
    console.log("this is a button");
  };

  showCalendarModal = event => {
    this.setState(
      {
        currentEventTitle: event.title,
        currentEventDescription: event.description,
        currentEventStart: event.start,
        currentEventEnd: event.end,
        currentEventId: event.eventId,
        currentLocation: event.location,
        currentIsExportable: event.is_exportable
      },
      () => {
        this.CustomCalendarModalRef.showModal();
      }
    );
  };

  showConfirmationDialog = () => {
    this.CustomConfirmationModalRef.showModal();
  };

  confirmation = eventId => {
    this.deleteEvent(eventId);
  };

  deleteEvent = eventId => {
    deleteEvent(eventId).finally(() => {
      this.getEvents(this.state.currentMonth, this.state.currentYear);
      this.CustomCalendarModalRef.hideModal();
      this.CustomConfirmationModalRef.hideModal();
    });
  };

  render() {
    const { calendarlist } = this.state;
    return (
      <div className="content">
        <CustomCalendarModal
          eventId={this.state.currentEventId}
          title={this.state.currentEventTitle}
          description={this.state.currentEventDescription}
          start={this.state.currentEventStart}
          end={this.state.currentEventEnd}
          location={this.state.currentLocation}
          is_exportable={this.state.currentIsExportable ? "True" : "False"}
          showConfirmationDialog={this.showConfirmationDialog}
          ref={ref => {
            this.CustomCalendarModalRef = ref;
          }}
        />
        <CustomConfirmationModal
          eventId={this.state.currentEventId}
          title={"Delete Event"}
          body={"Are sure do you want to delete this event?"}
          confirmation={this.confirmation}
          ref={ref => {
            this.CustomConfirmationModalRef = ref;
          }}
        />
        <Grid fluid>
          <CustomCard>
            <div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginLeft: 25,
                  marginRight: 25
                }}
              >
                <div>
                  <h3>My Events</h3>
                </div>

                <ul
                  style={{
                    columnCount: 3,
                    columnGap: 10,
                    listStyle: "none"
                  }}
                >
                  {calendarlist.map((item, index) => {
                    return (
                      <li>
                        <h5
                          style={
                            {
                              //   marginLeft: 10,
                              //  marginTop: 8,
                              //   alignSelf: "center"
                            }
                          }
                        >
                          <span
                            style={{
                              height: 15,
                              width: 15,
                              marginRight: 5,
                              borderColor: item.background_color,
                              borderStyle: "hidden",
                              backgroundColor: item.background_color,
                              display: "inline-block"
                            }}
                          />
                          {item.calendar_name}
                        </h5>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
            <Calendar
              localizer={localizer}
              startAccessor="start"
              endAccessor="end"
              defaultView="month"
              defaultDate={new Date()}
              style={{ height: 550, margin: 25 }}
              events={this.state.events}
              eventPropGetter={this.eventStyleGetter}
              popup={true}
              onNavigate={navigate => {
                let date = moment(navigate);

                this.setState(
                  {
                    currentMonth: date.month() + 1,
                    currentYear: date.year()
                  },
                  () => {
                    this.getEvents(date.month() + 1, date.year());
                  }
                );
              }}
              selectable={true}
              onSelectEvent={event => {
                this.showCalendarModal(event);
              }}
              views={{ month: true }}
            />
            <p
              style={{
                display: "flex",
                justifyContent: "right"
              }}
            >
              Events indicated with * contains location.
            </p>
          </CustomCard>
        </Grid>
      </div>
    );
  }
}
