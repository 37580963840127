import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import "./../../components/Auth/login.css";
import Button from "components/Button/CustomButton";
import CustomHeader from "../../components/Auth/CustomHeader";
import { forgetPassword } from "../../actions/auth";
import BaseClass from "../../components/BaseClass";

// TODO: No Dynamic rendering 
export default class ForgetPassword extends BaseClass {
  constructor(props) {
    super();
    this.state = {
      email: ""
    };
    this.forgetpassword = this.forgetpassword.bind(this);
  }

  formSubmit =e =>{
    e.preventDefault();
    this.forgetpassword()
  }
  forgetpassword = () => {
    if (this.validator.allValid()) {
      this.LoaderStart();
      forgetPassword(this.state.email)
        .then(json => {})
        .finally(() => {
          this.LoaderStop();
        });
    }
    else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  render() {
    return (
      <div>
      {/* TODO: Duplicated code in ForgorPassword, ResetPassword, etc.  */}
        <div className="App">
          <nav
            className="navbar navbar-expand-lg navbar-light fixed-top"
            style={{ height: "90px" }}
          >
            <div className="container">
              <CustomHeader />
            </div>
          </nav>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row"
          }}
        >
          <div style={{margin : "0 auto", marginTop: 100, float:"none", marginBottom: "10px" }}>
            <div className="auth">
              <form onSubmit={this.formSubmit}>
                <center><h3>Reset Password</h3><br/></center>
                <div className="form-group">
                  <label>Email address</label>
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Enter email"
                    value={this.state.email}
                    onChange={this.handleInputChange}
                    name="email"
                  />
                  {this.validator.message(
                    "email",
                    this.state.email,
                    "required|email"
                  )}
                </div>
                <br />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center"
                  }}
                >
                  <br />
                  <Button
                    loader={this.state.isLoading}
                    disabled={this.state.isLoading}
                    // onClick={this.forgetpassword}
                    text="Send mail"
                    type={"submit"}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
