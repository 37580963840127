// TODO: Unused imports
import React, { Component } from "react";
import { Grid, Row, Col } from "react-bootstrap";
import { DateRangePicker } from "react-dates";
import Card1 from "components/Card/Card.jsx";
import Select from "react-select";
import "bootstrap/dist/css/bootstrap.min.css";
import IconButton from "components/Button/CustomtextIconButton";
import CustomRadioButton from "components/CustomRadioButton/CustomRadioButton";
import CustomCheck from "components/CustomCheck/CustomCheck";
import { CustomCollapse } from "components";
import "components/Button/button.css";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import LoadingOverlay from "react-loading-overlay";
// Comments are code smells

// If you want to use the provided css
import "react-google-places-autocomplete/dist/assets/index.css";
import { getCalendarList } from "actions/calendar";
import moment from "moment";
import { exportMileageHistory, showWarningMsg } from "../../actions/export";
import Button from "components/Button/CustomButton";
import BaseClass from "components/BaseClass";

// TODO: What's the purpose of this file
class MilageExport extends BaseClass {
  constructor(props) {
    super(props);
    // TODO: Unwanted and unused state variables
    this.state = {
      startDate: null,
      endDate: null,
      selectedStartDate: null,
      selectedEndDate: null,
      selectedLocation: "",
      focusedInput: null,
      unit: "",
      isOpen1: false,
      isOpen2: false,
      isOpen3: false,
      isOpen4: false,
      calendarList: [],
      selectedCalendars: [],
      options: [
        { value: "chocolate", text: "Month/day/year hour:minutes" },
        { value: "strawberry", text: "Day/month/year hour:minutes" },
        { value: "vanilla", text: "Year/month/day hour:minutes" }
      ],
      option: [
        { value: "chocolate", text: "Hours:minutes:seconds" },
        { value: "strawberry", text: "Hours:minutes" },
        { value: "vanilla", text: "Hours (in Decimal)" }
      ],
      opt: [
        { value: "chocolate", text: "GMT" },
        { value: "strawberry", text: "IST" },
        { value: "vanilla", text: "UTc" }
      ],

      isLoading: false,
      isLoadingPDF: false,
      warningMsg: "",
      showWarningMsg: false
    };

    this.handleChangeValue = this.handleChangeValue.bind(this);
  }

  componentDidMount = () => {
    this.getCalendars();
    this.showWarning();
  };

  getCalendars = () => {
    getCalendarList().then(({ data }) => {
      this.setState({
        calendarList: data
      });
    });
  };

  showWarning = () => {
    showWarningMsg().then(({ data }) => {
      if (data != null) {
        this.setState({
          warningMsg: data,
          showWarningMsg: true
        });
      }
    });
  };

  createLegend(json) {
    var legend = [];
    for (var i = 0; i < json["names"].length; i++) {
      var type = "fa fa-circle text-" + json["types"][i];
      legend.push(<i className={type} key={i} />);
      legend.push(" ");
      legend.push(json["names"][i]);
    }
    return legend;
  }

  handleChangeValue = e => {
    const target = e.target;
    const value = target.value;
    const name = target.name;

    let selectedCalendars = this.state.selectedCalendars;

    if (!selectedCalendars.includes(value)) {
      selectedCalendars.push(value);
    } else {
      let index = selectedCalendars.indexOf(value);
      selectedCalendars.splice(index, 1);
    }

    this.setState({
      selectedCalendars
    });
  };

  setSelectedLocation = ({ description }) => {
    this.setState({
      selectedLocation: description
    });
  };

  exportMileageDocumentXLSX = () => {
    this.setState({
      isLoading: true
    });
    // TODO: Long parameter functions (Code Smell)
    exportMileageHistory(
      this.state.selectedStartDate,
      this.state.selectedEndDate,
      this.state.selectedLocation,
      this.state.selectedCalendars,
      this.state.unit,
      "xlsx"
    ).finally(() => {
      this.setState({
        isLoading: false
      });
    });
  };
  exportMileageDocumentPDF = () => {
    this.setState({
      isLoading: true
    });
    exportMileageHistory(
      this.state.selectedStartDate,
      this.state.selectedEndDate,
      this.state.selectedLocation,
      this.state.selectedCalendars,
      this.state.unit,
      "pdf"
    ).finally(() => {
      this.setState({
        isLoading: false
      });
    });
  };

  setSelectedDateRange = (start, end) => {
    let selectedStartDate = null;
    let selectedEndDate = null;

    if (start != null) {
      // TODO: Magic strings should be replaced with constants
      // TODO: One single format should be followed in the entire app
      selectedStartDate = moment(start._d).format("YYYY-MM-DD");
    }

    if (end != null) {
      selectedEndDate = moment(end._d).format("YYYY-MM-DD");
    }

    this.setState({
      selectedStartDate,
      selectedEndDate
    });
  };

  render() {
    const { calendarList } = this.state;
    return (
      <div className="content">
        {this.state.showWarningMsg == true ? (
          <div
            style={{
              width: "fit-content",
              backgroundColor: "rgba(255,0,0,0.3)",
              color: "white",
              border: "1px solid red",
              borderRadius: 3,
              margin: 20,
              padding: 10,
              textAlign: "center"
            }}
          >
            {this.state.warningMsg}
          </div>
        ) : (
          ""
        )}
        <Grid fluid>
          <LoadingOverlay
            active={this.state.isLoading}
            spinner
            text="In Process... Please Hold..."
          >
            <Row className="justify-content-md-center">
              <Col md={12}>
                <Card1
                  content={
                    <form>
                      <div style={{ marginLeft: 20 }}>
                        <h3>Calendars</h3>
                        <br />
                      </div>
                      <div style={{ marginLeft: 15 }}>
                        <table>
                          {calendarList.map((item, index) => {
                            return (
                              <tr>
                                <input
                                  disabled={this.state.isLoading}
                                  type="checkbox"
                                  onChange={this.handleChangeValue}
                                  value={`${item.calendar_id}`}
                                  id={`calendar_${item.calendar_id}`}
                                  name={`${item.calendar_name}`}
                                  style={{
                                    marginTop: 14,
                                    marginRight: 10,
                                    height: 16,
                                    width: 16
                                  }}
                                />
                                <td>
                                  {/* TODO: What this button does? */}
                                  {/* TODO: Why not component? */}
                                  <button
                                    disabled={this.state.isLoading}
                                    onClick={() => {
                                      //this.selectEvents(1);
                                    }}
                                    style={{
                                      height: 15,
                                      width: 15,
                                      borderColor: item.background_color,
                                      borderStyle: "hidden",
                                      backgroundColor: item.background_color
                                    }}
                                  />
                                </td>
                                <td>
                                  <label
                                    htmlFor={`calendar_${item.calendar_id}`}
                                    style={{
                                      marginLeft: 10,
                                      marginTop: 8,
                                      alignSelf: "center"
                                    }}
                                  >
                                    {item.calendar_name}
                                  </label>
                                </td>
                              </tr>
                            );
                          })}
                        </table>
                      </div>

                      <div style={{ marginLeft: 20 }}>
                        <h3>Range</h3>
                      </div>
                      <div style={{ marginLeft: 20 }}>
                        <DateRangePicker
                          disabled={this.state.isLoading}
                          startDateId="startDate"
                          endDateId="endDate"
                          isOutsideRange={() => false}
                          startDate={this.state.startDate}
                          endDate={this.state.endDate}
                          onDatesChange={({ startDate, endDate }) => {
                            this.setState({ startDate, endDate });
                            this.setSelectedDateRange(startDate, endDate);
                          }}
                          focusedInput={this.state.focusedInput}
                          onFocusChange={focusedInput => {
                            this.setState({ focusedInput });
                          }}
                        />
                      </div>
                      <br />
                      {/* <div style={{ margin: 20 }}>
                      <h3>From</h3>
                      <GooglePlacesAutocomplete
                        onSelect={this.setSelectedLocation}
                      />
                    </div> */}
                      <div style={{ margin: 20 }}>
                        <h3>Unit</h3>
                        <CustomRadioButton
                          disabled={this.state.isLoading}
                          name={"unit"}
                          id={"unit_km"}
                          value={"km"}
                          text="KM"
                          onChange={this.handleInputChange}
                        />
                        <CustomRadioButton
                          disabled={this.state.isLoading}
                          name={"unit"}
                          id={"unit_miles"}
                          value={"miles"}
                          text="Miles"
                          onChange={this.handleInputChange}
                        />
                      </div>

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center"
                        }}
                      >
                        <div style={{ marginRight: 20 }}>
                          <Button
                            text="Excel"
                            onClick={() => {
                              this.exportMileageDocumentXLSX();
                            }}
                            icon={"fa fa-file-excel-o"}
                            disabled={this.state.isLoading}
                            //loader={this.state.isLoading}
                          />
                          <span style={{ margin: 10 }}></span>
                        </div>
                        <div>
                          <Button
                            text="PDF"
                            onClick={() => {
                              this.exportMileageDocumentPDF();
                            }}
                            icon={"fa fa-file-pdf-o"}
                            disabled={this.state.isLoading}
                            // loader={this.state.isLoadingPDF}
                          />
                        </div>
                      </div>
                    </form>
                  }
                />
              </Col>
            </Row>
          </LoadingOverlay>
        </Grid>
      </div>
    );
  }
}

export default MilageExport;
