import React, { Component } from 'react'

export class YearPicker extends Component {

    onChange = (event) => {
        if (this.props.changeState != null) {
            this.props.change(this.props.changeState, event);
        }
    }

    render() {
        return (
            <select selected={this.props.selected} value={this.props.selected} onChange={(event) => this.onChange(event)} style={this.props.styles}>
                {this.props.years.map((year, index) => {
                    return (
                        <option value={index}>{year}</option>
                    )
                })}
            </select>
        )
    }
}

export default YearPicker
