import React, { Component } from "react";
import { Card, CardBody } from "reactstrap";
import PropTypes from "prop-types";

// TODO: What is a component? Is it a custom reusable element? 

/**
 * @augments {Component<{  children:node>}
 */
class CustomCard extends Component {
  render() {
    return (
      <Card>
        <CardBody style={{ padding: 10 }}>{this.props.children}</CardBody>
      </Card>
    );
  }
}

CustomCard.propTypes = {
  children: PropTypes.node
};

export default CustomCard;
