import React, { Component } from 'react';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';


import CheckoutPage from 'components/Stripe/CheckoutPage'
import { Modal, Button } from 'react-bootstrap';
import Loader from 'react-loader-spinner'
import {STRIPE_KEY} from '../../configs/Constants'


const stripePromise = loadStripe(STRIPE_KEY);

class CustomStripeConfirmationModal extends Component {

    constructor(props) {
      super(props)
    
      this.state = {
         isModalVisible: false, 
         credentials: {}
      };

      this.hideModal = this.hideModal.bind(this) ;
    };

    showModal = () => {
        this.setState({
            isModalVisible: true
        })
    }
    
    hideModal = () => {
        this.setState({
            isModalVisible: false
        })
    }

    showLoader = () => {
        this.setState({
            isLoading: true
        })
    }

    hideLoader = () => {
        this.setState({
            isLoading: false
        })
    }

    serverTransactionCredentials = (transactionCredentials) => {
        this.setState({
            credentials: transactionCredentials
        })
    }

    render() {
        return (
            <div>
                <Modal style={{ zIndex: 1500 }} show={this.state.isModalVisible} onHide={this.hideModal} >
                    {!this.state.isLoading && 
                        <div>
                            <Modal.Body>
                                <Elements stripe={stripePromise}>
                                    <CheckoutPage credentials={this.state.credentials} {...this.props} hideModal={this.hideModal} />
                                </Elements>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button onClick={() => this.hideModal()} >Close</Button>
                            </Modal.Footer>
                        </div>
                    }
                    {this.state.isLoading && 
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <Loader
                                style={{ maxWidth: 60, maxHeight: 60, margin: 20 }}
                                type="Oval" 
                                color="#000000" 
                                height={50} 
                                width={50}     
                            />
                        </div>
                    }
                </Modal>
            </div>
        );
    }
}

export default CustomStripeConfirmationModal;