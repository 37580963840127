import Card from "components/Card/Card.jsx";
import { BASE_URL } from "configs/Constants";
import React, { Component } from "react";
import { Col, Grid, Row } from "react-bootstrap";
import Iframe from "react-iframe";

class SingleCard extends Component {
  openHelpDocument = route => {
    route = route.replace("/admin", "");
    let params = `width=0,height=0,left=-1000,top=-1000`;
    let url = `${BASE_URL}../help/?page=${route}`;
    window.open(url, "Mileage2Go-Help", params);
  };
  render() {
    return (
      <Col md={4}>
        <Card
          onClick={() => {
            this.props.history.push(this.props.link);
          }}
          title={
            <div
              style={{
                textAlign: "center",
                fontSize: 25
              }}
            >
              <i class={this.props.icon}></i>
              <i
                className={"pe-7s-help1"}
                title="Open help manual"
                style={{
                  marginRight: 0,
                  float: "right",
                  fontSize: 20
                }}
                onClick={() => {
                  this.openHelpDocument(this.props.link);
                }}
              />
              <span
                style={{
                  marginLeft: 20
                }}
              >
                {this.props.title}
              </span>
            </div>
          }
          ctTableFullWidth
          ctTableResponsive
          content={
            <div
              style={{
                margin: 15,
                fontSize: 15
              }}
            >
              {this.props.description}
            </div>
          }
        />
      </Col>
    );
  }
}

class Home extends Component {
  constructor() {
    super();
    this.state = {};
  }
  render() {
    return (
      <div className="content home_page">
        <Grid fluid>
          <Row>
            <SingleCard
              {...this.props}
              link="/admin/Import"
              icon={"pe-7s-upload"}
              title="Import"
              description="Import data from Google Calendar or an Excel file."
            />
            <SingleCard
              {...this.props}
              link="/admin/mileage"
              icon={"pe-7s-car"}
              title="Mileage Export"
              description="Export calendar data and estimated mileages"
            />
            <SingleCard
              {...this.props}
              link="/admin/Subscriptions"
              icon={"pe-7s-credit"}
              title="Subscription"
              description="Sign up subscription plan based on features."
            />
          </Row>
          <Row>
            <SingleCard
              {...this.props}
              link="/admin/calender"
              icon={"pe-7s-date"}
              title="My Events"
              description="Lists events data imported from Google Calendar and/or Excel"
            />
            <SingleCard
              {...this.props}
              link="/admin/history"
              icon={"pe-7s-albums"}
              title="Export History"
              description="history of exports."
            />
            <SingleCard
              {...this.props}
              link="/admin/Invoice"
              icon={"pe-7s-wallet"}
              title="My Invoices"
              description="View subscription related invoices."
            />
          </Row>
          <Row>
            <Iframe
              url="https://www.youtube.com/embed/dRywPgKkC-0"
              width="100%"
              height="500px"
              display="initial"
              position="relative"
            />
          </Row>
        </Grid>
      </div>
    );
  }
}

export default Home;
