import React, { Component } from "react";
import Collapsible from "react-collapsible";
import PropTypes from "prop-types";
import "./collapse.css";

// TODO: This component is not used anywhere in the code 
/**
 * @augments {Component<{  children:node,  title:string>}
 */
class CustomCollapse extends Component {
  render() {
    return (
      <div>
        <Collapsible trigger={this.props.title}>
          {this.props.children}
        </Collapsible>
      </div>
    );
  }
}

CustomCollapse.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string
};

CustomCollapse.defaultProps = {
  title: "Title"
};

export default CustomCollapse;
