import { createNewSubscription, getSubscriptions } from "actions/subscriptions";
import { CustomStripeConfirmationModal } from "components";
import BaseClass from "components/BaseClass";
import MonthPicker from "components/MonthPicker/MonthPicker";
import YearPickerAlt from "components/YearPicker/YearPickerAlt";
import _ from "lodash";
import moment from "moment";
import React from "react";
import { Col, Grid, Row } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import "../assets/css/Subscriptions.css";
import "./HomePage/Second/assets/css/style-homepage.css";
import "./HomePage/Second/assets/vendor/bootstrap/css/bootstrap.css";
import "./HomePage/Second/assets/vendor/boxicons/css/boxicons.min.css";
import { ActiveSubscription } from "./subscription/ActiveSubscription";
import { FreeTrail } from "./subscription/FreeTrail";
import { TotalCreditPoints } from "./subscription/TotalCreditPoints";

const years = _.range(2019, 2026);
export const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec"
];

class Subscriptions extends BaseClass {
  CustomStripeConfirmationModalRef = null;
  subscriptionRef = null;
  ovalLoaderRef = null;
  subscriptionPeriodRef = null;

  constructor(props) {
    super(props);

    this.state = {
      subscriptions: [],
      selectedSubscription: null,
      monthYear: []
    };
  }

  subscribe = () => {
    this.CustomStripeConfirmationModalRef.showModal();
    this.CustomStripeConfirmationModalRef.showLoader();
    createNewSubscription({
      plan_id: this.state.selectedSubscription,
      start_month:
        parseInt(
          this.state.monthYear[this.state.selectedSubscription]?.start_month
        ) + 1,
      start_year: parseInt(
        this.state.monthYear[this.state.selectedSubscription]?.start_year
      )
    })
      .then(response => {
        if (response.data) {
          this.CustomStripeConfirmationModalRef.hideLoader();
          let transactionCredentials = response.data;
          this.CustomStripeConfirmationModalRef.serverTransactionCredentials(
            transactionCredentials
          );
        } else {
          this.CustomStripeConfirmationModalRef.hideModal();
        }
      })
      .finally(() => {
        this.CustomStripeConfirmationModalRef.hideLoader();
      });
  };

  async componentDidMount() {
    let subscriptionsResponse = await getSubscriptions();
    let subscriptions = subscriptionsResponse?.data;
    let monthYear = [];
    subscriptions.map((plan, i) => {
      if (plan.plan_type !== "yearly") {
        monthYear[plan.plan_id] = {
          start_year: moment()
            .toDate()
            .getFullYear(),
          start_month: moment()
            .toDate()
            .getMonth()
        };
      }
    });
    this.setState({ subscriptions, monthYear });
  }

  render() {
    return (
      <div style={{ marginTop: 100 }}>
        <div
          className={"homepage card"}
          style={{
            backgroundColor: "#ffffff",
            borderColor: "#ccd0d7",
            borderRadius: 4,
            margin: 30
          }}
        >
          <Grid fluid>
            <div>
              <CustomStripeConfirmationModal
                ref={ref => {
                  this.CustomStripeConfirmationModalRef = ref;
                }}
                {...this.props}
              />
            </div>
            <section id="pricing" class="pricing">
              <div
                style={{
                  display: "flex",
                  paddingRight: 35,
                  justifyContent: "right"
                }}
              >
                <TotalCreditPoints />
              </div>
              <div class="">
                <div class="section-title" dataAos="fade-up">
                  <Row>
                    {this.state.subscriptions &&
                      this.state.subscriptions.map((plan, i) => {
                        return (
                          <Col md={3}>
                            <div>
                              <br />
                              <div class="box featured">
                                <h3>{plan.plan_name}</h3>
                                <h4>
                                  <sup>{"$"}</sup>
                                  {Number(plan.plan_cost)}
                                </h4>
                                {plan.plan_type == "yearly" && (
                                  <ul>
                                    <li>{plan.plan_description}</li>
                                  </ul>
                                )}
                                {plan.plan_type == "monthly" && (
                                  <div>
                                    <MonthPicker
                                      selected={parseInt(
                                        this.state.monthYear[plan.plan_id]
                                          ?.start_month
                                      )}
                                      change={event => {
                                        let monthYear = this.state.monthYear;
                                        monthYear[plan.plan_id] = {
                                          start_month: event.target.value,
                                          start_year: this.state.monthYear[
                                            plan.plan_id
                                          ]?.start_year
                                        };
                                        this.setState({
                                          monthYear: monthYear
                                        });
                                      }}
                                    />
                                    <YearPickerAlt
                                      selected={parseInt(
                                        this.state.monthYear[plan.plan_id]
                                          ?.start_year
                                      )}
                                      years={years}
                                      change={event => {
                                        let monthYear = this.state.monthYear;
                                        monthYear[plan.plan_id] = {
                                          start_year: event.target.value,
                                          start_month: this.state.monthYear[
                                            plan.plan_id
                                          ]?.start_month
                                        };
                                        this.setState({
                                          monthYear: monthYear
                                        });
                                      }}
                                    />

                                    {plan.interval > 1 && (
                                      <>
                                        <label
                                          style={{
                                            marginLeft: 5,
                                            marginRight: 5
                                          }}
                                        >
                                          {" "}
                                          to{" "}
                                        </label>
                                        {moment(
                                          `${parseInt(
                                            this.state.monthYear[plan.plan_id]
                                              ?.start_year
                                          )}-${parseInt(
                                            this.state.monthYear[plan.plan_id]
                                              ?.start_month
                                          ) + 1}-1`
                                        )
                                          .add(plan.interval - 1, "months")
                                          .format("MMM YYYY")}
                                      </>
                                    )}
                                  </div>
                                )}
                                <div class="btn-wrap">
                                  <a
                                    href="#"
                                    class="btn-buy"
                                    onClick={() => {
                                      this.setState(
                                        {
                                          selectedSubscription: plan.plan_id
                                        },
                                        () => {
                                          let confimMsg = "";
                                          if (plan.plan_type == "yearly") {
                                            confimMsg = plan.plan_description;
                                          }
                                          if (plan.plan_type == "monthly") {
                                            const start = moment(
                                              `${parseInt(
                                                this.state.monthYear[
                                                  plan.plan_id
                                                ]?.start_year
                                              )}-${parseInt(
                                                this.state.monthYear[
                                                  plan.plan_id
                                                ]?.start_month
                                              ) + 1}-1`
                                            ).format("MM/DD/YYYY");

                                            const end = moment(
                                              `${parseInt(
                                                this.state.monthYear[
                                                  plan.plan_id
                                                ]?.start_year
                                              )}-${parseInt(
                                                this.state.monthYear[
                                                  plan.plan_id
                                                ]?.start_month
                                              ) + 1}-1`
                                            )
                                              .add(plan.interval - 1, "months")
                                              .endOf("month")
                                              .format("MM/DD/YYYY");
                                            confimMsg = `from ${start} to ${end} `;
                                          }
                                          if (
                                            window.confirm(
                                              `You are about to subscribe ${confimMsg}. Is this correct?`
                                            )
                                          ) {
                                            this.subscribe();
                                          }
                                        }
                                      );
                                    }}
                                  >
                                    Subscribe
                                  </a>
                                </div>
                              </div>
                            </div>
                          </Col>
                        );
                      })}
                  </Row>
                  <hr />
                  <div>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <FreeTrail />
                    </div>
                  </div>
                </div>
              </div>
              <ActiveSubscription />
            </section>
          </Grid>
        </div>
      </div>
    );
  }
}

export default Subscriptions;
