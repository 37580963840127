import React, { Component } from "react";
import PropTypes from "prop-types";

class CustomCheck extends Component {
  constructor(props) {
    super(props);
    this.state = {
      is_checked: props.isChecked ? true : false
    };
    this.handleClick = this.handleClick.bind(this);
  }
  handleClick = () => {
    this.setState({ is_checked: !this.state.is_checked });
  };

  render() {
    const { isChecked, id, text, inline, ...rest } = this.props;
    const classes =
      inline !== undefined ? "checkbox checkbox-inline" : "checkbox";
    return (
      <div
        className={classes}
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center"
        }}
      >
        <input
          id={id}
          type="checkbox"
          onChange={this.handleClick}
          checked={this.state.is_checked}
          {...rest}
        />
        <label htmlFor={id}></label>
        <p htmlFor={id} style={{ marginLeft: 3, marginTop: 10 }}>
          {text}
        </p>
      </div>
    );
  }
}

CustomCheck.propTypes = {
  isChecked: PropTypes.bool,
  id: PropTypes.string,
  text: PropTypes.string,
  handleClick: PropTypes.func,
  is_checked: PropTypes.func
};

CustomCheck.defaultProps = {
  text: "Button"
};

export default CustomCheck;
