import React, { Component } from "react";
import "./button.css";
import PropTypes from "prop-types";
import Colors from "./../../configs/Colors";

class Button extends Component {
  render() {
    return (
      <button {...this.props} className="btn" type="button">
        <i
          className={this.props.icon}
          style={{
            fontSize: this.props.fontSize,
            color: this.props.color
          }}
        />
      </button>
    );
  }
}

Button.propTypes = {
  icon: PropTypes.string,
  fontSize: PropTypes.number,
  color: PropTypes.string
};

Button.defaultProps = {
  icon: "fa fa-file",
  fontSize: 18,
  color: Colors.WHITE
};

export default Button;
