import React, { Component } from 'react'
import PropTypes from 'prop-types'

// TODO: File in wrong directory 
class InitialAvatar extends Component {
    constructor(props) {
        super(props)

        this.state = {
            userName: ''
        }
    }
    
    render() {
        return (
            <div
                id="profilePicture"
                style={{
                    width: this.props.width,
                    height: this.props.height,
                    borderRadius: "100%",
                    display: 'flex',
                    background: "#512DA8",
                    justifyContent: 'center',
                    alignSelf: 'center',
                    textAlign: 'center',
                }}
            >
                <div style={{ height: 10 }} >
                <span style={{
                    position: "relative",
                    color: "#FFFFFF",
                    top: '70%',
                    left: '3%',
                    fontSize: this.props.textSize
                }} >{this.props.text}</span>
                </div>
            </div>
        )
    }
}

InitialAvatar.propTypes = {
  height: PropTypes.number,
  text: PropTypes.string,
  textSize: PropTypes.number,
  width: PropTypes.number
}

InitialAvatar.defaultProps = {
    height: 35,
    width: 35,
    textSize: 28,
    text: "A"
}

export default InitialAvatar
