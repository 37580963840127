import React, { Component } from "react";
import "./../App.css";

export class SidebarImages extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedImage: ''
        }
    }

    updateImage = (image) => {
        this.setState({
            selectedImage: image
        });
        this.props.handleImageClick(image);
    }

  render() {
    return (
      <div style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        marginLeft: "0px"
      }}>
        {this.props.backgroundImages.map((image, index) => {
            return (
                <div style={{ marginRight: "40px" }}>
                    <li className={
                        this.state.selectedImage === image ? "active-state" : ""
                    }>
                    <a className="img-holder switch-trigger"
                        onClick={() => {
                            this.updateImage(image)
                        }}
                    >
                        <div  style={{width: "70", backgroundColor:"red" }}>
                        <img
                        width="70"
                        style={{ maxHeight: 100}}
                        src={image}
                        alt={"Background Image " + (index+1)}
                        />
                        </div>
                    </a>
                    </li>
                </div>
            )
        })}
      </div>
    );
  }
}

export default SidebarImages