// TODO: Usage of utils is a code smell
import { Media } from "reactstrap";
import React from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { DEV } from "../configs/Constants";
import {
  LOCALSTORAGE_KEY_AUTH_TOKEN,
  LOCALSTORAGE_KEY_USER_INFO
} from "./../configs/Constants";

export const showSuccessMsg = message => {
  toast.success(
    function({ closeToast }) {
      return (
        <Media>
          <Media body>
            <p>{message}</p>
          </Media>
        </Media>
      );
    },
    {
      position: toast.POSITION.TOP_CENTER
    }
  );
};
export const showErrorMsg = message => {
  toast.error(
    function({ closeToast }) {
      return (
        <Media>
          <Media body>
            <p>{message}</p>
          </Media>
        </Media>
      );
    },
    {
      position: toast.POSITION.TOP_CENTER
    }
  );
};
export const normalizeResponseErrors = res => {
  // TODO: Where does it show failure messages?
  if (res.ok) {
    return res.json().then(json => {
      if (json.status == "SUCCESS") {
        if (json.message) {
          showSuccessMsg(json.message);
        }
        return json;
      } else if (json.status == "REDIRECT") {
        //    TODO: What if I want to redirect, if the signup fails - Does it show success message?
        if (json.message) {
          showSuccessMsg(json.message);
        }
        window.open(json.url, "_blank");
      } else {
        return Promise.reject(json);
      }
    });
  } else {
    if (
      res.headers.has("content-type") &&
      res.headers.get("content-type").startsWith("application/json")
    ) {
      return res.json().then(err => Promise.reject(err));
    }
    return Promise.reject({
      code: res.status,
      status: "ERROR",
      message: res.statusText
    });
  }
  return res;
};
export const fetchErrorHandler = err => {
  if (err.message) {
    showErrorMsg(err.message);
  }
};
export const navigatePage = page => {
  this.props.history.push(page);
};

export const devLog = (key, value) => {
  if (DEV) {
    console.log(key, value);
  }
};

export const saveToken = token => {
  localStorage.setItem(LOCALSTORAGE_KEY_AUTH_TOKEN, token);
};
export const getToken = () => {
  return localStorage.getItem(LOCALSTORAGE_KEY_AUTH_TOKEN);
};
export const removeToken = () => {
  localStorage.removeItem(LOCALSTORAGE_KEY_AUTH_TOKEN);
};

export const saveUserInfo = info => {
  localStorage.setItem(LOCALSTORAGE_KEY_USER_INFO, JSON.stringify(info));
};

export const getUserInfo = () => {
  return JSON.parse(localStorage.getItem(LOCALSTORAGE_KEY_USER_INFO));
};

export const removeUserInfo = () => {
  localStorage.removeItem(LOCALSTORAGE_KEY_USER_INFO);
};

export const getFYOptions = () => {
  return [
    {
      year: 2018,
      year_text: "FY 2018-2019"
    },
    {
      year: 2019,
      year_text: "FY 2019-2020"
    },
    {
      year: 2020,
      year_text: "FY 2020-2021"
    },
    {
      year: 2021,
      year_text: "FY 2021-2022"
    }
  ];
};

export const isLeapYear = year => {
  if (year % 4 == 0) {
    if (year % 100 == 0) {
      if (year % 400 == 0) return true;
      else return false;
    } else {
      return true;
    }
  } else {
    return false;
  }
};
