import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import "./../../components/Auth/login.css";
import Button from "components/Button/CustomButton";
import CustomHeader from "../../components/Auth/CustomHeader";
import { resetPassword, saveToken } from "../../actions/auth";
import { DEV } from "../../configs/Constants";
import BaseClass from "../../components/BaseClass";

export default class ResetPassword extends BaseClass {
  constructor(props) {
    super();
    this.state = {
      newPassword: "",
      rePassword: "",
      isLoading: false
    };
    this.resetPassword = this.resetPassword.bind(this);
  }
  devFill = () => {
    if (DEV) {
      this.setState({
        newPassword: "",
        rePassword: ""
      });
    }
  };
  componentDidMount = () => {
    this.devFill();
  };
  formSubmit =e =>{
    e.preventDefault();
    this.resetPassword()
  }
  resetPassword = () => {
    const token = this.props.match.params.token;
    if (this.validator.allValid()) {
      this.LoaderStart();
      if (this.state.newPassword === this.state.rePassword) {
        resetPassword(this.state.newPassword, token)
          .then(json => {
            if (json != null) {
              // console.log("this is the json", json)
              // saveToken(json.token);
              this.props.history.push("/login")
            }
          })
          .finally(() => {
            this.LoaderStop();
          });
      }
    }
    else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };
  render() {
    return (
      <div>
        <div className="App">
          <nav
            className="navbar navbar-expand-lg navbar-light fixed-top"
            style={{ height: "90px" }}
          >
            <div className="container">
              <CustomHeader />
            </div>
          </nav>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row"
          }}
        >
          <div style={{margin : "0 auto", marginTop: 100, float:"none",marginBottom: "10px" }}>
            <div className="auth">
              <form onSubmit={this.formSubmit}>
                <center><h3>Reset Password</h3><br/></center>

                {/* TODO: Duplicated code in Login, Signup, ForgotPassword, ResetPassword and wherever input is obtained */}
                <div className="form-group">
                  <label>New Password</label>
                  <input
                    type="password"
                    className="form-control"
                    placeholder="Enter new password"
                    value={this.state.newPassword}
                    onChange={this.handleInputChange}
                    name="newPassword"
                  />
                  {this.validator.message(
                    "newPassword",
                    this.state.newPassword,
                    "required|string"
                  )}
                </div>

                <div className="form-group">
                  <label>Re-enter new password</label>
                  <input
                    type="password"
                    className="form-control"
                    placeholder="Re-enter new password"
                    value={this.state.rePassword}
                    onChange={this.handleInputChange}
                    name="rePassword"
                  />
                  {this.validator.message(
                    "rePassword",
                    this.state.rePassword,
                    "required|string"
                  )}
                </div>
                <br />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center"
                  }}
                >
                    <Button
                      loader={this.state.isLoading}
                      disabled={this.state.isLoading}
                      // onClick={this.resetPassword}
                      text="Change"
                      type={"submit"}
                    />
                    <br />
                </div>

                <br />
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
