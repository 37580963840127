import moment from "moment";
import React, { useEffect, useState } from "react";
import useFetch, { CachePolicies } from "use-http";

import { activateTrialPeriod } from "../../actions/subscriptions";

export function FreeTrail() {
  const [trailCompleted, setTrailCompleted] = useState(false);
  const [start_trial_date, setStartTrialDate] = useState(
    moment().format("YYYY-MM-DD")
  );
  const [end_trial_date, setEndTrialDate] = useState(
    moment()
      .add(1, "week")
      .format("YYYY-MM-DD")
  );

  const { loading, error, data = {}, get } = useFetch(
    "/subscription/trial-status",
    {},
    []
  );
  useEffect(() => {
    if (data?.data) {
      setTrailCompleted(data?.data?.status);
      if (data?.data?.status) {
        setStartTrialDate(data?.data?.start_date);
        setEndTrialDate(data?.data?.end_date);
      } else {
      }
    }
  }, [data]);

  return (
    <div>
      <br />
      <div className="box featured">
        <h3>{"Free Trial"}</h3>
        <h4>
          <sup>{"USD"}</sup>
          {0.0}
        </h4>
        <ul>
          <li>One week subscription!</li>
        </ul>
        <input
          type="date"
          placeholder={"yyyy-mm-dd"}
          value={start_trial_date}
          disabled={trailCompleted}
          onChange={event => {
            setStartTrialDate(
              moment(event.target.valueAsDate).format("YYYY-MM-DD")
            );
            setEndTrialDate(
              moment(event.target.valueAsDate)
                .add(6, "days")
                .format("YYYY-MM-DD")
            );
          }}
        />
        {" to "}
        <input type="date" disabled value={end_trial_date} />
        <div className="btn-wrap">
          <button
            disabled={trailCompleted}
            className={!trailCompleted && "btn-buy"}
            onClick={() => {
              activateTrialPeriod({
                start_date: start_trial_date
              }).then(() => {
                get("");
              });
            }}
          >
            {trailCompleted ? "Activated" : "Activate"}
          </button>
        </div>
      </div>
    </div>
  );
}
