import React, { Component } from "react";
import "./button.css";
import PropTypes from "prop-types";

class IconButton extends Component {
  render() {
    return (
      <button
        className={this.props.className}
        type="button"
        style={{ fontSize: 15, fontWeight: "bold", width: this.props.width }}
        onClick={() => this.props.onClick()}
      >
        <i className={this.props.icon}></i>&nbsp; &nbsp;
        {this.props.text}
      </button>
    );
  }
}

IconButton.propTypes = {
  onClick: PropTypes.func,
  text: PropTypes.string,
  icon: PropTypes.string,
  width: PropTypes.string,
  className: PropTypes.string
};

IconButton.defaultProps = {
  text: "Button",
  width: "125px",
  className: "btn"
};

export default IconButton;
