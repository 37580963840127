import React, { Component } from "react";
import PropTypes from "prop-types";

// TODO: Is this component required? Trade off
class PageSplitter extends Component {
  render() {
    return (
      <div>
        <div
          style={{
            width: "100%",
            height: 20,
            borderBottomColor: "#C0C0C0",
            borderBottomWidth: 1.0,
            borderBottomStyle: "solid",
            textAlign: "center"
          }}
        >
          <span
            style={{
              fontSize: 22,
              backgroundColor: this.props.backgroundColor,
              alignSelf: "flex-end"
            }}
          >
            <span
              style={{
                fontSize: 16,
                fontWeight: "100",
                color: "#C0C0C0"
              }}
            >
            {/* TODO: What if I want to display a different text? */}
              or
            </span>
          </span>
        </div>
      </div>
    );
  }
}
PageSplitter.propTypes = {
  backgroundColor: PropTypes.string
};

PageSplitter.defaultProps = {
  backgroundColor: "white"
};
export default PageSplitter;
