import React from "react";
import GoogleLogin from "react-google-login";
import { BASE_URL, GOOGLE_CLIENT_ID } from "configs/Constants";
import { withRouter } from "react-router-dom";
import {  loginWithGoogle } from "./../../actions/auth";
import {getToken,saveToken, saveUserInfo} from '../../utils/utilities'
import { showErrorMsg } from "../../utils/utilities";

class GoogleButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id_token: "",
      access_token: "",
      expires_in: "",
      created_at: ""
    };
  }
  showFailure = response => {
    if(response.error){
      showErrorMsg(response.error)
    }else{
      showErrorMsg("login with google error")
    }
  }

  sendDetails = response => {
    loginWithGoogle(response)
      .then(response => {
        if (response) {
          saveToken(response.token);
          saveUserInfo(response.user);
          this.props.history.push('/admin/home')
        }
      });
  };
  responseGoogle = response => {
    this.sendDetails(response);
  };

  render() {
    return (
      <div className="App">
        <GoogleLogin
          id="google-login"
          theme= "dark"
          clientId={GOOGLE_CLIENT_ID}
          buttonText="Login with google"
          scope="https://www.googleapis.com/auth/calendar.readonly"
          onSuccess={this.responseGoogle.bind(this)}
          onFailure={this.showFailure.bind(this)}
        />
      </div>
    );
  }
}
export default withRouter(GoogleButton);
