import React, { Component } from "react";

export class YearPickerAlt extends Component {
  onChange = event => {
    if (this.props.changeState != null) {
      this.props.change(this.props.changeState, event);
    } else {
      this.props.change(event);
    }
  };

  render() {
    return (
      <select
        selected={this.props.selected}
        value={this.props.selected}
        onChange={event => this.onChange(event)}
        style={this.props.styles}
      >
        {this.props.years.map((year, index) => {
          return <option value={year}>{year}</option>;
        })}
      </select>
    );
  }
}

export default YearPickerAlt;
