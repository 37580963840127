import React, { Component } from "react";
import { Grid, Row, Col } from "react-bootstrap";
import { post } from "axios";
import { Form } from "reactstrap";
import PageSplitter from "components/PageSplitter/PageSplitter";
import { Card } from "components/Card/Card.jsx";
// import GoogleButton from "react-google-button";
import GoogleLogin from "react-google-login";
import "components/Auth/login.css";
import "bootstrap/dist/css/bootstrap.css";
import { CustomButton } from "components";
import OvalLoader from "./../components/CustomLoader/OvalLoader";
import "components/Button/button.css";
import { BASE_URL, GOOGLE_CLIENT_ID } from "./../configs/Constants";
import {
  getToken,
  saveToken,
  saveUserInfo,
  showErrorMsg,
  getFYOptions,
  isLeapYear
} from "./../utils/utilities";
import {
  importFromGoogle,
  importFromCsv,
  validateImport
} from "./../actions/import";
import ImportPreview from "../components/ImportPreviewGrid/ImportPreview";
import moment from "moment";
import MonthPicker from "components/MonthPicker/MonthPicker";
import YearPicker from "components/YearPicker/YearPicker";
import GoogleButton from "react-google-button";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { DEV } from "configs/Constants";

const years = ["2019", "2020", "2021", "2022", "2023", "2024", "2025"];
// javascript will always follows 0 as Jan month
const daysInMonth = {
  0: "31",
  1: "28",
  2: "31",
  3: "30",
  4: "31",
  5: "30",
  6: "31",
  7: "31",
  8: "30",
  9: "31",
  10: "30",
  11: "31"
};

// TODO: Lots of unwanted code
class Imports extends Component {
  ovalLoaderRef = null;

  constructor(props) {
    super(props);
    this.state = {
      file: null,
      isLoading: false,
      isUploading: false,
      originalFile: "",
      subscribedFinancialYear: 1,
      subscriptionYears: [],
      financialYears: [],
      filename: "Choose your file...",
      subFromMonth: 0,
      subFromYear: 4,
      subToMonth: 11,
      subToYear: 4,
      selectedLocation: ""
    };
    this.file = this.file.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
    this.fileUpload = this.fileUpload.bind(this);
    this.importPreview = React.createRef();

    if (DEV) {
      this.state.selectedLocation = "Las Vegas";
      this.state.subToMonth = 0;
    }
  }
  updateFinancialYear = event => {
    this.setState({
      subscribedFinancialYear: event.target.value
    });
  };
  prepareYearData = () => {
    let subscriptionYears = [];
    let financialYears = [];
    let FY = getFYOptions();

    FY.map((item, index) => {
      subscriptionYears.push(item.year);
      financialYears.push(item.year_text);
    });

    this.setState({
      financialYears,
      subscriptionYears
    });
  };
  componentDidMount = () => {
    this.prepareYearData();
  };

  showLoader = () => {
    this.setState({
      isLoading: true
    });
  };

  hideLoader = () => {
    this.setState({
      isLoading: false
    });
  };

  onFormSubmit = () => {
    // e.preventDefault(); // Stop form submit
    this.fileUpload(this.state.file).then(response => {
      console.log(response.data);
    });
  };

  // TODO: Purpose of this function?
  file = () => {
    console.log("this button is clicked");
  };
  onChange = e => {
    let files = e.target.files || e.dataTransfer.files;
    if (!files.length) return;

    this.readFile(files[0]);
    this.setState({ filename: files[0].name });
  };
  readFile(file) {
    let reader = new FileReader();
    reader.onload = e => {
      this.setState({ file: e.target.result, originalFile: file });
    };
    reader.readAsDataURL(file);
  }
  fileUpload = () => {
    if (this.state.originalFile == "") {
      return;
    }
    this.setState({
      isUploading: true
    });
    const formData = new FormData();
    formData.append(
      "file",
      this.state.originalFile,
      this.state.originalFile.name
    );
    importFromCsv(formData)
      .then(res => {
        if (res && res.status && res.status == "SUCCESS") {
          this.importPreview.showPreview(res.data, false);
        }
      })
      .finally(() => {
        this.setState({
          isUploading: false
        });
      });
  };

  showFailure = response => {
    console.log("login failure", response);
    showErrorMsg(
      "Unable to import " + response.error + " please try once again."
    );
  };
  validateDateRange = async () => {
    let subFromMonth = parseInt(this.state.subFromMonth);
    let subToMonth = parseInt(this.state.subToMonth);

    let fromDateString =
      years[this.state.subFromYear] + "-" + (subFromMonth + 1) + "-01";

    let endDate;
    if (subFromMonth == 1) {
      if (isLeapYear(years[this.state.subToYear])) {
        endDate = "29";
      } else {
        endDate = "28";
      }
    } else {
      endDate = daysInMonth[subToMonth];
    }

    let toDateString =
      years[this.state.subToYear] + "-" + (subToMonth + 1) + "-" + endDate;

    const fromDate = moment(fromDateString, "YYYY-MM-DD");
    const toDate = moment(toDateString, "YYYY-MM-DD");

    let dateDifference = toDate.diff(fromDate, "days");

    if (dateDifference > 366) {
      showErrorMsg("Import limited to a maximum of 12 months at once");
      return false;
    } else if (dateDifference <= 0) {
      showErrorMsg("Please select valid date range");
      return false;
    }

    let from = moment(fromDateString).format("YYYY-MM");
    let to = moment(toDateString).format("YYYY-MM");

    return await validateImport(from, to)
      .then(response => {
        if (response.data && response.data.overwriteEvents) {
          if (
            window.confirm(
              "You have already imported " +
                response.data.noOfEventsImported +
                " events from the selected date range. Are you sure, you want to import the events again? "
            )
          ) {
            return true;
          }
          return false;
        }
        return true;
      })
      .catch(error => {
        console.log("Error", error);
        return false;
      });
  };
  sendDetails = response => {
    this.showLoader();
    let subFromMonth = parseInt(this.state.subFromMonth);
    let subToMonth = parseInt(this.state.subToMonth);
    let fromRange = years[this.state.subFromYear] + "-" + (subFromMonth + 1);
    let toRange = years[this.state.subToYear] + "-" + (subToMonth + 1);
    importFromGoogle(response, fromRange, toRange, this.state.selectedLocation)
      .then(res => {
        if (res && res.status && res.status == "SUCCESS") {
          this.importPreview.showPreview(res.data);
        }
      })
      .finally(() => {
        this.hideLoader();
      });
  };
  responseGoogle = response => {
    this.sendDetails(response);
    // this.props.history.push("/admin/import");
  };

  updateSubscriptionRange = (status, event) => {
    this.setState({
      [status]: event.target.value
    });
  };
  setSelectedLocation = ({ description }) => {
    this.setState({
      selectedLocation: description
    });
  };

  render() {
    return (
      <div className="content">
        <ImportPreview
          ref={ref => {
            this.importPreview = ref;
          }}
        />
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Card
                content={
                  <Form style={{ margin: 40 }}>
                    <h3 style={{}}>Import From Google Calendar</h3>
                    <br />
                    <div
                      style={{
                        margin: 30
                      }}
                    >
                      <div
                        style={{
                          display: "flex"
                          // justifyContent: "center"
                        }}
                      >
                        {/* <GoogleButton /> */}
                        {this.state.isLoading && (
                          <OvalLoader
                            ref={ref => {
                              this.ovalLoaderRef = ref;
                            }}
                          />
                        )}
                        {!this.state.isLoading && (
                          <div>
                            <div>
                              <label>Starting Point(HOME):</label>
                              <br />
                              <GooglePlacesAutocomplete
                                initialValue={this.state.selectedLocation}
                                onSelect={this.setSelectedLocation}
                              />
                              <br />
                              <label>Select a month range to Import</label>
                              <br />
                              <MonthPicker
                                selected={this.state.subFromMonth}
                                change={this.updateSubscriptionRange}
                                changeState="subFromMonth"
                              />
                              <YearPicker
                                selected={this.state.subFromYear}
                                change={this.updateSubscriptionRange}
                                changeState="subFromYear"
                                styles={{ marginLeft: 10 }}
                                years={years}
                              />
                              <label
                                style={{ marginLeft: 10, marginRight: 10 }}
                              >
                                {" "}
                                to{" "}
                              </label>
                              <MonthPicker
                                selected={this.state.subToMonth}
                                change={this.updateSubscriptionRange}
                                changeState="subToMonth"
                              />
                              <YearPicker
                                selected={this.state.subToYear}
                                change={this.updateSubscriptionRange}
                                changeState="subToYear"
                                styles={{ marginLeft: 10 }}
                                years={years}
                              />
                            </div>
                            <br />
                            <br />

                            <GoogleLogin
                              render={renderProps => (
                                <GoogleButton
                                  onClick={async () => {
                                    if (this.state.selectedLocation) {
                                      let response = await this.validateDateRange();
                                      if (response) {
                                        renderProps.onClick();
                                      }
                                    } else {
                                      showErrorMsg(
                                        "Please select any starting point."
                                      );
                                    }
                                  }}
                                  disabled={renderProps.disabled}
                                  label="Import from Google Calendar"
                                />
                              )}
                              theme="dark"
                              clientId={GOOGLE_CLIENT_ID}
                              buttonText="Import from Google Calendar"
                              scope="https://www.googleapis.com/auth/calendar.readonly"
                              onSuccess={this.responseGoogle.bind(this)}
                              onFailure={this.showFailure.bind(this)}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                    <br />
                    {/* <div
                      style={{
                        display: "flex",
                        justifyContent: "center"
                      }}
                    >
                      <CustomButton text={"Sync now"} />
                    </div> */}
                    <br />
                    <PageSplitter />

                    <h3>Import from Excel</h3>
                    <br />
                    <div
                      style={{
                        margin: 30
                      }}
                    >
                      <div
                        style={{
                          display: "flex"
                        }}
                      >
                        <div
                          style={{
                            display: "flex"
                          }}
                        >
                          <label class="file">
                            <input
                              type="file"
                              id="file"
                              aria-label="File browser example"
                              onChange={this.onChange}
                            />
                            <span class="file-custom">
                              {this.state.filename}{" "}
                            </span>
                          </label>
                        </div>
                      </div>
                      <br />
                      <div>
                        <a href={BASE_URL + "../import.xlsx"}>
                          Click here to download sample excel file.
                        </a>
                      </div>
                      <br />
                      <div
                        style={{
                          display: "flex"
                        }}
                      >
                        <CustomButton
                          onClick={this.fileUpload}
                          text={"Upload"}
                          loader={this.state.isUploading}
                          disabled={this.state.isUploading}
                        />
                      </div>
                    </div>
                  </Form>
                }
              ></Card>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default Imports;
