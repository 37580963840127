import React, { useEffect, useState } from "react";
import useFetch, { CachePolicies } from "use-http";
import { months } from "../Subscriptions";

export function ActiveSubscription() {
  const { loading, error, data = [] } = useFetch(
    "/subscription/subscribed-months",
    {},
    []
  );
  const [subscribedMonth, setSubscribedMonth] = useState({});
  useEffect(() => {
    if (data?.data) {
      let subMonth = {};
      data?.data?.map((month, i) => {
        if (!subMonth[month.year]) subMonth[month.year] = [];
        subMonth[month.year].push([months[month.month - 1]]);
      });
      setSubscribedMonth(subMonth);
    }
  }, [data]);
  const subscribedMonths = Object.keys(subscribedMonth);
  return (
    <>
      <h2 style={{ marginLeft: 10 }}>Active Subscription</h2>
      <table className="customers" style={{ marginLeft: 10 }}>
        <thead>
          <th style={{ fontSize: 16, fontWeight: 600 }}>Year</th>
          <th style={{ fontSize: 16, fontWeight: 600 }}>Month</th>
          <th style={{ fontSize: 16, fontWeight: 600 }}>Status</th>
        </thead>
        {!loading && subscribedMonths.length === 0 && (
          <div>No Active Subscriptions</div>
        )}
        {subscribedMonths.map((year, i) => {
          const month = subscribedMonth[year];
          return (
            <tbody
              style={{
                backgroundColor: `${i % 2 !== 0 ? "#f2f2f2" : "#ffffff"}`
              }}
            >
              <td>{year}</td>
              <td>{month.join(", ")}</td>
              <td>
                <i
                  className="fa fa-check-circle"
                  style={{ color: "green" }}
                ></i>
              </td>
            </tbody>
          );
        })}
      </table>
    </>
  );
}
