import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import "./ImportPreviewGrid.css";
import moment from "moment";
import { CustomButton } from "components/Button";
import ImportPreviewGrid from "./ImportPreviewGrid";

const LOCATION_COL = 4;
export default class ImportPreview extends Component {
  seperatedData = []; //sepeated by tab index
  ovalLoaderRef = null;

  constructor(props) {
    super(props);
    this.state = {
      isModalVisible: false,
      isLoading: false,
      isTab: true,
      tabs: [],
      tabBadges: [],
      activeTabIndex: 0,
    };
    this.importPreviewGrid = React.createRef();
  }
  showPreview = (data, isTab = true) => {
    //now spliting the data
    let seperatedData = {};
    this.seperatedData = [];
    let seperatedDataBadgeCount = {};
    let tabs = [];
    let tabBadges = [];
    if (isTab) {
      data.map((previewData, row) => {
        let tabName = moment(previewData.start_date).format("MMM YYYY");
        if (!(tabName in seperatedDataBadgeCount)) {
          seperatedDataBadgeCount[tabName] = 1;
        } else {
          seperatedDataBadgeCount[tabName]++;
        }

        if (!(tabName in seperatedData)) {
          seperatedData[tabName] = [];
        }
        seperatedData[tabName].push(previewData);
      });

      for (let tabName in seperatedDataBadgeCount) {
        tabs.push(tabName);
        tabBadges.push(seperatedDataBadgeCount[tabName]);
        this.seperatedData.push(seperatedData[tabName]);
      }
    }
    this.setState({
      isTab,
      tabs,
      tabBadges,
      isModalVisible: true,
    });
    if (isTab) {
      this.updateTabIndex(0);
    } else {
      this.importPreviewGrid.showPreview(data);
    }
  };
  hidePreview = () => {
    this.setState({
      isModalVisible: false,
    });
  };
  updateGrid = () => {
    this.setState({
      isLoading: true,
    });
    let activeTabIndex = this.state.activeTabIndex;
    this.importPreviewGrid
      .updateGrid()
      .then((data) => {
        this.seperatedData[activeTabIndex] = data;
      })
      .finally(() => {
        this.setState({
          isLoading: false,
        });
      });
  };

  updateTabIndex = (activeTabIndex) => {
    this.setState({ activeTabIndex });
    this.importPreviewGrid.showPreview(this.seperatedData[activeTabIndex]);
  };
  render() {
    return (
      <Modal
        className="preview-grid"
        style={{ zIndex: 1500 }}
        show={this.state.isModalVisible}
        onHide={this.hidePreview}
      >
        {/* <Modal.Title>Importing from Google Calendar</Modal.Title> */}
        <Modal.Header closeButton>
          <Modal.Title>Import Preview</Modal.Title>
          <p>Click update after making changes.</p>
        </Modal.Header>

        <Modal.Body>
          {this.state.isTab && (
            <ul class="nav nav-tabs">
              {this.state.tabs.map((tabName, index) => {
                return (
                  <li
                    key={tabName}
                    className={
                      index == this.state.activeTabIndex ? "active" : ""
                    }
                  >
                    <a
                      onClick={() => {
                        this.updateTabIndex(index);
                      }}
                      href="#"
                    >
                      {tabName}{" "}
                      <span class="badge">{this.state.tabBadges[index]}</span>
                    </a>
                  </li>
                );
              })}
            </ul>
          )}
          <ImportPreviewGrid
            ref={(ref) => {
              this.importPreviewGrid = ref;
            }}
          />
        </Modal.Body>
        <Modal.Footer>
          <div
            style={{
              float: "left",
            }}
          >
            Status:
            <Button
              style={{
                background: "red",
              }}
              value="ERROR"
              className="btn btn-danger"
            />{" "}
            - Not Saved
            <Button
              style={{
                background: "green",
              }}
              value="ERROR"
              className="btn btn-danger"
            />{" "}
            - Saved
          </div>
          {/* <Button onClick={() => this.updateGrid()} >Update</Button> */}
          <CustomButton
            disabled={this.state.isLoading}
            loader={this.state.isLoading}
            text={"Update"}
            onClick={() => this.updateGrid()}
          />
          {/* <Button onClick={() => this.hidePreview()} >Close</Button> */}
        </Modal.Footer>
      </Modal>
    );
  }
}
