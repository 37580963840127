module.exports = {
  PRIMARY_BLUE: "#007bff",
  LIGHT_BLUE: "#4285f4",
  BLACK: "#000000",
  WHITE: "#ffffff",
  GREEN: "#14ca1a",
  SKY_BLUE: "#3FD2C7",
  GREEN_SKY_BLUE: "#99DDFF",
  BLUE: "#00458B"
};
