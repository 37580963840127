import React, { Component } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { CustomIconButton } from 'components' ;
import { MAP_URL_REDIRECT } from './../../configs/Constants' ;

class CustomCalendarModal extends React.PureComponent {

    CustomConfirmationModalRef = null 

    constructor(props) {
        super(props)
        this.state = {
            isVisible: false
        };

        this.hideModal = this.hideModal.bind(this)
    };

    showModal = () => {
        this.setState({
            isVisible: true
        })
    }

    hideModal = () => {
        this.setState({
            isVisible: false
        })
    }

    render() {
        return (
            <Modal
                style={{ zIndex: 1500 }} 
                show={this.state.isVisible} 
                onHide={this.hideModal}
                bsSize="auto"    
            >
            <Modal.Header closeButton>
        <Modal.Title>{this.props.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <table style={{ margin: 5, width: '100%' }} >
                    <tr style={styles.tableRow} >
                        <td style={ styles.tableDataTitle } ><div><h4>Title: </h4></div></td>
                        <td style={ styles.tableDataValue } ><div><p>{this.props.title}</p></div></td>
                    </tr>

                    <tr style={styles.tableRow} >
                        <td style={ styles.tableDataTitle } ><h4>Description: </h4></td>
                        <td style={ styles.tableDataValue } >
                            <p>
                                {this.props.description == null ? "-" : this.props.description}
                            </p>
                        </td>
                    </tr>
                    <tr style={styles.tableRow}>
                        <td style={ styles.tableDataTitle } ><h4>Location: </h4></td>
                        <td style={ styles.tableDataValue } >
                            <p>{this.props.location == null ? "-" : <p>{this.props.location}</p>}</p>
                        </td>
                    </tr>
                    <tr style={styles.tableRow} >
                        <td style={ styles.tableDataTitle } ><h4>Exportable : </h4></td>
                        <td style={ styles.tableDataValue } ><p>{this.props.is_exportable}</p></td>
                    </tr>
                    <tr style={styles.tableRow} >
                        <td style={ styles.tableDataTitle } ><h4>Start: </h4></td>
                        <td style={ styles.tableDataValue } ><p>{this.props.start}</p></td>
                    </tr>
                    <tr style={styles.tableRow} >
                        <td style={ styles.tableDataTitle } ><h4>End: </h4></td>
                        <td style={ styles.tableDataValue } ><p>{this.props.end}</p></td>
                    </tr>
                </table>
            </Modal.Body>
            <Modal.Footer>
              <CustomIconButton
                // TODO: Don't know what the confirmation dialog is meant for 
                onClick={() => this.props.showConfirmationDialog()}
                style={{ 
                    backgroundColor: 'red', 
                    borderColor: 'red' 
                }}
                icon={"fa fa-trash"}
                fontSize={25}
              />
            </Modal.Footer>
          </Modal>
        )
    }

}

const styles = {
    tableRow: { 
        borderBottomStyle: 'solid', 
        borderBottomWidth: 0.5, 
        borderTopStyle: 'solid', 
        borderTopWidth: 0.5, 
        padding: 20,
        margin: 20,
        justifyContent: 'space-evenly',
    },
    tableDataValue: {
        // verticalAlign: 'top'
    },
    tableDataValue: { 
        // verticalAlign: 'top', 
        paddingTop: 8, 
        paddingLeft: 10 
    },
}

export default CustomCalendarModal ;