import React, { Component } from "react";
import { Grid, Row, Col, Table } from "react-bootstrap";
import Card from "components/Card/Card.jsx";
import { thArray, tdArray } from "variables/Variables.jsx";
import Button from "./../components/Button/CustomButton";
import { getAllInvoices } from "actions/subscriptions";

class Invoice extends Component {
  constructor() {
    super();
    this.state = {
      invoices: [],
    };
  }

  getAllInvoices() {
    getAllInvoices().then(({ data }) => {
      if (data) {
        this.setState({
          invoices: data,
        });
      }
    });
  }
  componentDidMount() {
    this.getAllInvoices();
  }
  render() {
    return (
      <div className="content">
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Card
                title="Invoice"
                ctTableFullWidth
                ctTableResponsive
                content={
                  <Table striped hover>
                    <thead>
                      <tr>
                        {thArray.map((prop, key) => {
                          return <th key={key}>{prop}</th>;
                        })}
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.invoices.map((prop, key) => {
                        return (
                          <tr key={key}>
                            <td>{prop.payment_date}</td>
                            <td>{prop.ref_no}</td>
                            <td>{prop.payment_type}</td>
                            <td>{prop.amount}</td>

                            <td>
                              <div>
                                <Button
                                  width={'auto'}
                                  icon={"fa fa-download"}
                                  onClick={() => {
                                    window.open(prop.file_name, "_blank");
                                  }}
                                  style={{
                                    paddingHorizondal: 10,
                                    paddingTop: 10,
                                  }}
                                />
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                }
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default Invoice;
